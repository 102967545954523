import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { flow, filter, isEqual } from "lodash/fp"
import { api, authApi, BASE_URL } from "../api"
import { createRequestStatusSelector } from "../util"

export const listReviewsByApp = createAsyncThunk(
  `reviews/getByApp`,
  async (appId) => await api.get(`${BASE_URL}/apps/${appId}/reviews`),
)

export const listReviewsByAccount = createAsyncThunk(
  `reviews/getByAccount`,
  async (accountId) =>
    await authApi.get(`${BASE_URL}/accounts/${accountId}/reviews`),
)

export const createReview = createAsyncThunk(
  `reviews/create`,
  async ({ rating, content = "", accountId, appId, reviewType }) =>
    await authApi.post(`${BASE_URL}/reviews`, {
      rating,
      content,
      account_id: accountId,
      app_id: appId,
      review_type: reviewType,
    }),
)

export const updateReview = createAsyncThunk(
  `reviews/update`,
  async ({ rating, content = "", reviewType, reviewId }) =>
    await authApi.put(`${BASE_URL}/reviews/${reviewId}`, {
      rating,
      content,
      review_type: reviewType,
    }),
)

const isAPendingAction = isPending(listReviewsByApp, listReviewsByAccount)
const isAFufilledAction = isFulfilled(listReviewsByApp, listReviewsByAccount)
const isARejectedAction = isRejected(listReviewsByApp, listReviewsByAccount)

const reviewsAdapter = createEntityAdapter()
const initialState = reviewsAdapter.getInitialState({
  requests: {},
})
export const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(createReview.fulfilled, (state, action) => {
        const review = action.payload
        reviewsAdapter.setOne(state, review)
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        const review = action.payload
        reviewsAdapter.upsertOne(state, review)
      })
      .addCase(listReviewsByApp.fulfilled, (state, action) => {
        const reviews = action.payload
        reviewsAdapter.setMany(state, reviews)
      })
      .addCase(listReviewsByAccount.fulfilled, (state, action) => {
        const reviews = action.payload
        reviewsAdapter.setMany(state, reviews)
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        if (listReviewsByApp.pending.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isAFufilledAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        if (listReviewsByApp.fulfilled.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        if (listReviewsByApp.rejected.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
  },
})

// exports
export const reviewsActions = { ...reviewsSlice.actions }
export const reviewsReducer = reviewsSlice.reducer

// selectors
export const {
  selectRequestStatus: selectAppsRequestStatus,
  zipWithRequestStatus,
} = createRequestStatusSelector(reviewsSlice.name)

export const {
  selectAll: selectAllReviews,
  selectById: selectReviewById,
  selectEntities: selectReviewEntities,
} = reviewsAdapter.getSelectors((state) => state.reviews)

export const selectReviewsByApp =
  (appId, withStats = false) =>
  (state) => {
    const reviews = selectAllReviews(state).filter((a) => a.app_id === appId)
    const rInfo = state.reviews.requests.all
    const isLoading = rInfo?.status === "pending"
    const error = rInfo?.error
    const withContent = reviews.filter((r) => r.review_type === "review")
    const result = { data: withContent, isLoading, error }
    if (withStats) {
      return { ...result, stats: mapReviewsToStats(reviews) }
    }
    return result
  }

export const selectExistingReview = (appId, accountId) => (state) => {
  const reviews = selectAllReviews(state)
  const maybeReview = reviews.find(
    (r) => r.app_id === appId && r.account_id === accountId,
  )
  return maybeReview
}

export const selectReviewsByAccount = (accountId) => (state) => {
  if (!accountId) zipWithRequestStatus(state)([])

  const result = flow(
    selectAllReviews,
    filter((r) => isEqual(accountId)(r.account_id)),
    zipWithRequestStatus(state),
  )(state)

  return result
}

export const mapReviewsToStats = (reviews) => {
  if (reviews.length === 0) {
    return {
      average: 0,
      totalCount: 0,
      counts: [
        {
          rating: "1",
          count: 0,
        },
        {
          rating: "2",
          count: 0,
        },
        {
          rating: "3",
          count: 0,
        },
        {
          rating: "4",
          count: 0,
        },
        {
          rating: "5",
          count: 0,
        },
      ],
    }
  }

  const ratings = reviews.map((r) => r.rating)
  const average = ratings.reduce((p, c) => p + c, 0) / ratings.length
  const totalCount = ratings.length
  const rr = ratings.reduce(
    (acc, rating) => {
      acc[rating]++
      return acc
    },
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  )

  const counts = Object.entries(rr).map(([rating, count]) => ({
    rating,
    count,
  }))

  return { average, totalCount, counts }
}
