import { useField } from "formik"

const DisabledTextField = ({ label, name }) => {
  const [field] = useField({ name, type: "text" })
  return (
    <div className="border-none relative pointer-events-none">
      <label htmlFor="name" className="block text-xs font-medium text-gray-900">
        {label}
      </label>
      <input
        {...field}
        spellCheck={false}
        readOnly
        className="block p-4 w-full rounded-md border-none ring-0 outline-none placeholder-gray-500 text-gray-500 bg-neutral-100"
      />
    </div>
  )
}

export default DisabledTextField
