import {
  AppsIcon,
  GameIcon,
  SocialIcon,
  ProductivityIcon,
  CryptoIcon,
  ShoppingIcon,
  EntertainmentIcon,
  BriefcaseIcon,
  HammerIcon,
  EducationIcon,
  FinanceIcon,
  FitnessIcon,
  FoodIcon,
  DesignIcon,
  KidsIcon,
  LifestyleIcon,
  MedicalIcon,
  MusicIcon,
  NavigationIcon,
  NewsIcon,
  MediaIcon,
  ReferenceIcon,
  SportsIcon,
  TravelIcon,
  UtilitiesIcon,
  WandIcon,
  WeatherIcon,
  FeaturedIcon,
} from "../../../shared/assets/CustomIcons"

// Preserve order of categories and categoryIcons lists
// If adding subcategories, add an "all" subcategory to the beginning of the list

const categories = [
  { id: "featured", name: "Featured", color: "black", icon: FeaturedIcon },
  { id: "all", name: "All Apps", color: "black", icon: AppsIcon },
  { id: "ai", name: "AI", color: "black", icon: WandIcon },
  { id: "business", name: "Business", icon: BriefcaseIcon },
  {
    id: "crypto",
    name: "Crypto",
    icon: CryptoIcon,
    color: "yellow",
    subCategories: [
      { id: "all", name: "All Crypto" },
      { id: "exchanges", name: "Exchanges" },
      { id: "ethereum", name: "Ethereum" },
      { id: "layerzero", name: "LayerZero" },
      { id: "chia", name: "Chia" },
      { id: "btc", name: "Bitcoin" },
      { id: "solana", name: "Solana" },
      { id: "polygon", name: "Polygon" },
      { id: "bnb", name: "BNB" },
      { id: "avalanche", name: "Avalanche" },
      { id: "arbitrum", name: "Arbitrum" },
      { id: "flow", name: "Flow" },
      { id: "aptos", name: "Aptos" },
      { id: "xrp", name: "XRP" },
      { id: "optimism", name: "Optimism" },
      { id: "cosmos", name: "Cosmos" },
      { id: "polkadot", name: "Polkadot" },
    ],
  },
  { id: "developer_tools", name: "Dev Tools", icon: HammerIcon },
  { id: "education", name: "Education", icon: EducationIcon },
  { id: "entertainment", name: "Entertainment", icon: EntertainmentIcon },
  { id: "finance", name: "Finance", icon: FinanceIcon },
  { id: "food_drink", name: "Food & Drink", icon: FoodIcon },
  {
    id: "games",
    name: "Games",
    icon: GameIcon,
    color: "blue",
    subCategories: [
      { id: "all", name: "All Games" },
      { id: "multiplayer", name: "Multiplayer" },
      { id: "singleplayer", name: "Singleplayer" },
      { id: "boardgame", name: "Boardgame" },
      { id: "strategy", name: "Strategy" },
    ],
  },
  { id: "graphics_design", name: "Graphics & Design", icon: DesignIcon },
  { id: "health_fitness", name: "Health & Fitness", icon: FitnessIcon },
  { id: "kids", name: "Kids", icon: KidsIcon },
  { id: "lifestyle", name: "Lifestyle", icon: LifestyleIcon },
  { id: "medical", name: "Medical", icon: MedicalIcon },
  { id: "music", name: "Music", icon: MusicIcon },
  { id: "navigation", name: "Navigation", icon: NavigationIcon },
  { id: "news", name: "News", icon: NewsIcon },
  { id: "photo_video", name: "Photo & Video", icon: MediaIcon },
  {
    id: "productivity",
    name: "Productivity",
    icon: ProductivityIcon,
    color: "purple",
  },
  { id: "reference", name: "Reference", icon: ReferenceIcon },
  { id: "shopping", name: "Shopping", icon: ShoppingIcon, color: "red" },
  { id: "social", name: "Social", icon: SocialIcon, color: "teal" },
  { id: "sports", name: "Sports", icon: SportsIcon },
  { id: "travel", name: "Travel", icon: TravelIcon },
  { id: "utilities", name: "Utilities", icon: UtilitiesIcon },
  { id: "weather", name: "Weather", icon: WeatherIcon },
]

export { categories }
