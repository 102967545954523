import { Suspense, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { selectAccountsByOwner } from "../../../api/accounts/accounts.slice"
import { willExpireIn } from "../../../api/api"
import { authActions } from "../../../api/auth/auth.slice"
import { useAuth } from "../../hooks/useAuth"

const withAuth = (Component, accountType) => (props) => {
  const { accessToken, expiresAt, refreshToken, refreshTokenExpiresAt } =
    useAuth()
  const dispatch = useDispatch()
  const location = useLocation()

  const isAccessExpired = !accessToken || willExpireIn(expiresAt, 0)
  const isRefreshExpired =
    !refreshToken || willExpireIn(refreshTokenExpiresAt, 0)

  const { data: accounts } = useSelector(selectAccountsByOwner())

  useEffect(() => {
    if (isAccessExpired && isRefreshExpired) {
      dispatch(authActions.logout())
    }
  }, [dispatch, isAccessExpired, isRefreshExpired])

  if (
    accounts?.length &&
    !accounts.find((account) => account.account_type === accountType)
  ) {
    return <Navigate to="/no-match" state={{ from: "/" }} replace />
  }

  if (!accessToken || (isAccessExpired && isRefreshExpired)) {
    // Redirect them to the /login page, but save the current location they were
    // on so we can send them back after they login
    return <Navigate to="/auth/login" state={{ from: location }} replace />
  } else {
    return (
      <Suspense fallback="Loading...">
        <Component {...props} />
      </Suspense>
    )
  }
}

export default withAuth
