import { createAsyncThunk, createSlice, isFulfilled } from "@reduxjs/toolkit"
import { isEmpty, omitBy } from "lodash/fp"
import { api, authApi, BASE_URL, willExpireIn } from "../api"

export const login = createAsyncThunk(
  `users/login`,
  async ({ email, password }) =>
    await api.post(`${BASE_URL}/users/login`, {
      email,
      password,
    }),
)

export const logout = createAsyncThunk(
  `users/logout`,
  async (_, { getState }) => {
    const { refreshToken, refreshTokenExpiresAt } = getState().auth
    const isRefreshExpired =
      !refreshToken || willExpireIn(refreshTokenExpiresAt, 0)
    if (isRefreshExpired) return Promise.resolve()

    return await api.post(`${BASE_URL}/users/logout`, {
      refresh_token: getState().auth?.refreshToken,
    })
  },
)

export const signUp = createAsyncThunk(
  `users/signup`,
  async ({ email, password, first_name, last_name, username }) =>
    await api.post(`${BASE_URL}/users/signup`, {
      email,
      password,
      first_name,
      last_name,
      username,
    }),
)

export const renewUserSession = createAsyncThunk(
  `users/session/renew`,
  async (_, { getState }) =>
    await api.post(`${BASE_URL}/users/sessions/renew`, {
      refresh_token: getState().auth?.refreshToken,
    }),
)

export const updateUser = createAsyncThunk(
  `users/update`,
  async ({ userId, email, firstName, lastName }) =>
    await authApi.put(
      `${BASE_URL}/users/${userId}`,
      omitBy(isEmpty)({
        email,
        first_name: firstName,
        last_name: lastName,
      }),
    ),
)

export const resetPassword = createAsyncThunk(
  `users/password/reset`,
  async ({ userId, currentPassword, newPassword }) =>
    await authApi.put(
      `${BASE_URL}/users/${userId}/password`,
      omitBy(isEmpty)({
        current_password: currentPassword,
        new_password: newPassword,
      }),
    ),
)

export const checkUsername = createAsyncThunk(
  `users/check/username`,
  async (username) => await api.head(`${BASE_URL}/users/username/${username}`),
)

export const forgotPasswordLink = createAsyncThunk(
  `users/forgot-password`,
  async ({ email }) =>
    await api.post(`${BASE_URL}/users/forgot-password`, {
      email,
    }),
)

export const checkPasswordResetToken = createAsyncThunk(
  `users/reset-password/check-token`,
  async (token) =>
    await api.get(`${BASE_URL}/users/reset-password/verify?token=${token}`),
)

export const submitPasswordReset = createAsyncThunk(
  `users/reset-password`,
  async ({ token: reset_token, password: new_password }) =>
    await api.post(`${BASE_URL}/users/reset-password`, {
      new_password,
      reset_token,
    }),
)

const initialState = () => ({
  accessToken: JSON.parse(localStorage.getItem("accessToken")),
  expiresAt: JSON.parse(localStorage.getItem("expiresAt")),
  refreshToken: JSON.parse(localStorage.getItem("refreshToken")),
  refreshTokenExpiresAt: JSON.parse(
    localStorage.getItem("refreshTokenExpiresAt"),
  ),
  user: JSON.parse(localStorage.getItem("user")),
  error: null,
})

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState(),
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading"
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
      .addCase(logout.fulfilled, (_) => {
        localStorage.removeItem("user")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("expiresAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiresAt")

        // return fresh initialState
        return initialState()
      })
      .addCase(renewUserSession.fulfilled, (state, action) => {
        const {
          access_token: accessToken,
          access_token_expires_at: expiresAt,
        } = action.payload

        localStorage.setItem("accessToken", JSON.stringify(accessToken))
        localStorage.setItem("expiresAt", JSON.stringify(expiresAt))

        state.accessToken = accessToken
        state.expiresAt = expiresAt
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded"

        const {
          user,
          access_token: accessToken,
          access_token_expires_at: expiresAt,
        } = action.payload

        localStorage.setItem("user", JSON.stringify(user))
        localStorage.setItem("accessToken", JSON.stringify(accessToken))
        localStorage.setItem("expiresAt", JSON.stringify(expiresAt))

        state.user = user
        state.accessToken = accessToken
        state.expiresAt = expiresAt
      })
      .addMatcher(isFulfilled(login, signUp), (state, action) => {
        state.status = "succeeded"

        const {
          user,
          access_token: accessToken,
          access_token_expires_at: expiresAt,
          refresh_token: refreshToken,
          refresh_token_expires_at: refreshTokenExpiresAt,
        } = action.payload

        localStorage.setItem("user", JSON.stringify(user))
        localStorage.setItem("accessToken", JSON.stringify(accessToken))
        localStorage.setItem("expiresAt", JSON.stringify(expiresAt))
        localStorage.setItem("refreshToken", JSON.stringify(refreshToken))
        localStorage.setItem(
          "refreshTokenExpiresAt",
          JSON.stringify(refreshTokenExpiresAt),
        )

        // check if we should update cached userId
        const cachedUserId = JSON.parse(localStorage.getItem("_userId"))
        if (cachedUserId === null || cachedUserId !== user.id) {
          localStorage.setItem("_userId", JSON.stringify(user.id))
        }

        state.user = user
        state.accessToken = accessToken
        state.expiresAt = expiresAt
        state.refreshToken = refreshToken
        state.refreshTokenExpiresAt = refreshTokenExpiresAt
      })
  },
})

// exports
export const authActions = {
  ...authSlice.actions,
  login,
  signUp,
  renewUserSession,
  logout,
  forgotPasswordLink,
}
export const authReducer = authSlice.reducer

// selectors
export const selectAccessToken = (state) => ({
  accessToken: state.auth.accessToken,
  expiresAt: state.auth.expiresAt,
  refreshToken: state.auth.refreshToken,
  refreshTokenExpiresAt: state.auth.refreshTokenExpiresAt,
})
export const selectAuthUser = (state) => state.auth.user || {}
