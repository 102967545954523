import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { filter, flow, isEmpty, isUndefined, map, omitBy } from "lodash/fp"
import { api, authApi, BASE_URL } from "../api"
import { selectAllManifests } from "../manifests/manifests.slice"
import {
  selectClaimedAppIds,
  selectClaimedAppsEntities,
} from "../claimedApps/claimedApps.slice"
import { createRequestStatusSelector } from "../util"
import { selectAllOverrides } from "../overrides/overrides.slice"
import { selectAccountByType } from "../accounts/accounts.slice"

export const updateApp = createAsyncThunk(
  `apps/update`,
  async ({ appId, publish, sitePath }) =>
    await authApi.put(
      `${BASE_URL}/apps/${appId}`,
      omitBy(isUndefined)({
        publish,
        site_path: sitePath,
      }),
    ),
)

export const deleteApp = createAsyncThunk(
  `apps/delete`,
  async ({ appId }) => await authApi.delete(`${BASE_URL}/apps/${appId}`),
)

export const createListing = createAsyncThunk(
  `apps/createListing`,
  async ({ url }) => {
    return await authApi.post(`${BASE_URL}/listings`, {
      url,
    })
  },
)

export const fetchClaimedListings = createAsyncThunk(
  `apps/listings/claimed`,
  async (admin = false) => {
    const list = await authApi.get(`${BASE_URL}/listings/claimed`)
    return (list || []).map(({ app, content }) => ({ ...app, content }))
  },
)

export const fetchListings = createAsyncThunk(
  `apps/listings`,
  async (admin = false) => {
    let list
    if (admin) {
      list = await authApi.get(`${BASE_URL}/admin/listings`)
    } else {
      list = await api.get(`${BASE_URL}/listings`)
    }
    return list.map(({ app, content }) => ({
      ...app,
      content,
    }))
  },
)

export const fetchListing = createAsyncThunk(
  `apps/fetchListing`,
  async ({ sitePath, admin = false }) => {
    if (admin) {
      return await authApi.get(`${BASE_URL}/admin/listings/${sitePath}`)
    } else {
      return await api.get(`${BASE_URL}/listings/${sitePath}`)
    }
  },
)

export const fetchListingsByAppIds = createAsyncThunk(
  `apps/fetchListingByAppIds`,
  async ({ app_ids }) => {
    const list = await api.post(`${BASE_URL}/listings/by_app_ids`, {
      app_ids: app_ids,
    })
    return (list || []).map(({ app, content, tags = [] }) => ({
      ...app,
      content,
      tags,
    }))
  },
)

export const fetchAllContent = createAsyncThunk(
  `apps/fetchAllContent`,
  async ({ sitePath, overrideType = "developer" }) => {
    return await authApi.get(
      `${BASE_URL}/listings/${sitePath}/all_content?override_type=${overrideType}`,
    )
  },
)

export const fetchStaticListings = createAsyncThunk(
  `apps/fetchStaticListings`,
  async () => {
    const list = await api.get(`${BASE_URL}/listings/home`)
    return list.map(({ app, content, tags = [] }) => ({
      ...app,
      content,
      tags,
    }))
  },
)

const isAPendingAction = isPending(
  // getAppBySitePath,
  fetchListings,
  fetchStaticListings,
  fetchListing,
  fetchListingsByAppIds,
)
const isAFulfilledAction = isFulfilled(
  // getAppBySitePath,
  fetchListings,
  fetchStaticListings,
  fetchListing,
  fetchListingsByAppIds,
)
const isARejectedAction = isRejected(
  // getAppBySitePath,
  fetchListings,
  fetchStaticListings,
  fetchListing,
  fetchListingsByAppIds,
)

const appsAdapter = createEntityAdapter()
const initialState = appsAdapter.getInitialState({
  requests: {},
})
export const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListings.fulfilled, (state, action) => {
        const list = action.payload
        appsAdapter.upsertMany(state, list)
      })
      .addCase(fetchStaticListings.fulfilled, (state, action) => {
        const list = action.payload
        appsAdapter.upsertMany(state, list)
      })
      .addCase(fetchClaimedListings.fulfilled, (state, action) => {
        const list = action.payload
        appsAdapter.upsertMany(state, list)
      })
      .addCase(fetchListing.fulfilled, (state, action) => {
        const item = action.payload
        appsAdapter.upsertOne(state, item)
      })
      .addCase(fetchListingsByAppIds.fulfilled, (state, action) => {
        const list = action.payload
        appsAdapter.upsertMany(state, list)
      })
      .addCase(deleteApp.fulfilled, (state, action) => {
        const appId = action.meta?.arg?.appId
        if (appId) {
          appsAdapter.removeOne(state, appId)
        }
      })
      .addCase(updateApp.fulfilled, (state, action) => {
        const app = action.payload
        appsAdapter.upsertOne(state, app)
      })
      .addCase(createListing.fulfilled, (state, action) => {
        const app = action.payload
        appsAdapter.setOne(state, app)
      })
      .addCase(fetchAllContent.fulfilled, (state, action) => {
        const { app, content } = action.payload
        if (app && content && Object.values(content).length) {
          appsAdapter.upsertOne(state, { ...app, content })
        }
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        const id = action.type.replace("/pending", "")
        state.requests[id] = status
      })
      .addMatcher(isAFulfilledAction, (state, action) => {
        const status = { status: "fulfilled", error: null }
        const id = action.type.replace("/fulfilled", "")
        state.requests[id] = status
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        const id = action.type.replace("/rejected", "")
        state.requests[id] = status
      })
  },
})

// exports
export const appsActions = {
  ...appsSlice.actions,
  createListing,
  fetchAllContent,
}
export const appsReducer = appsSlice.reducer

// selectors
export const {
  selectRequestStatus: selectAppsRequestStatus,
  zipWithRequestStatus,
} = createRequestStatusSelector(appsSlice.name)

export const {
  selectAll: selectAllApps,
  selectById: selectAppById,
  selectEntities: selectAppEntities,
} = appsAdapter.getSelectors((state) => state.apps)
export const selectAppsStatus = (state) => state.apps.status

export const selectApp = (appId) => (state) => selectAppById(state, appId)
export const selectApps = (appIds) => (state) =>
  appIds
    ?.map((appId) => selectAppById(state, appId))
    .filter((app) => !isEmpty(app))

export const selectAppBySitePath = (sitePath) => (state) =>
  selectAllApps(state).find((a) => a.site_path === sitePath)

export const selectClaimedListings = (state) => {
  const appEntities = selectAppEntities(state)
  const claimedEntities = selectClaimedAppsEntities(state)
  const { data: devAccount } = selectAccountByType("developer")(state)

  const cl = flow(
    selectClaimedAppIds,
    map((id) =>
      Object.assign({}, appEntities[id], { _claim: claimedEntities[id] }),
    ),
    filter("account_id", devAccount?.id),
    filter((v) => v._claim?.verified_status !== "failed"),
    format,
  )(state)
  return cl
}

const formatApp = ({ content, tags = [], ...app } = {}) => {
  let tmp = Object.assign({}, content)
  if (content?.icons) {
    if (Array.isArray(content.icons) && content.icons.length >= 1) {
      const icon =
        content.icons.find((i) => i.src.includes("512x512")) || content.icons[0]
      tmp.icon = icon.src
    } else {
      tmp.icon = content.icons
    }
  }

  return { app, tags, content: tmp }
}
const format = (list) => {
  return list.map(formatApp)
}

const isPublished = (list) => {
  return list.filter((item) => item.publish)
}

// Home (done)
// SearchCombobox (done)
export const selectListings = (state) => {
  return flow(
    selectAllApps,
    isPublished,
    format,
    zipWithRequestStatus(state, fetchListings.typePrefix),
  )(state)
}

// AdminTable (done)
export const selectListingsAdmin = (state) => {
  return flow(
    selectAllApps,
    format,
    zipWithRequestStatus(state, fetchListings.typePrefix),
  )(state)
}

// AdminApp (done)
// Listing (done)
export const selectListing = (sitePath) => (state) => {
  const findApp = (apps) => apps.find((a) => a.site_path === sitePath)

  return flow(
    selectAllApps,
    findApp,
    formatApp,
    zipWithRequestStatus(state, fetchListing.typePrefix),
  )(state)
}

export const groupByTags =
  (
    fn = (item) => item.id, // id selector fn
  ) =>
  (apps) =>
    apps.reduce((acc, item) => {
      const { tags = [] } = item
      tags.forEach((tag) => {
        if (!acc[tag]) {
          acc[tag] = []
        }
        acc[tag].push(fn(item))
      })
      return acc
    }, {})

export const selectStaticListingIds = (state) => {
  return flow(
    selectAllApps,
    groupByTags((a) => a.id),
    zipWithRequestStatus(state, fetchStaticListings.typePrefix),
  )(state)
}

export const selectListingById = (appId) => (state) => {
  const appById = (appId) => (state) => selectAppById(state, appId)
  return flow(appById(appId), formatApp)(state)
}

export const selectAllContent =
  (sitePath, overrideType = "developer") =>
  (state) => {
    const app = selectAllApps(state)?.find((a) => a.site_path === sitePath)

    const all = selectAllOverrides(state)
    const override = all?.find(
      (o) => o.app_id === app?.id && o?.override_type === overrideType,
    )
    const global = all?.find(
      (o) => o.app_id === app?.id && o?.override_type === "global",
    )
    const manifest = selectAllManifests(state)?.find(
      (o) => o.app_id === app?.id,
    )

    return flow(
      () => ({ app, override, manifest, global }),
      zipWithRequestStatus(state, fetchAllContent.typePrefix),
    )(state)
  }
