import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { renewUserSession } from "../../api/auth/auth.slice"
import { fetchAllOwnedLists, getFavorites } from "../../api/lists/lists.slice"
import { useIsLoggedIn } from "./useIsLoggedIn"

export const useMainLoader = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()
  useEffect(() => {
    if (!isLoggedIn) return
    dispatch(getFavorites())
    dispatch(fetchAllOwnedLists())
    dispatch(renewUserSession())
  }, [dispatch, isLoggedIn])
}
