import { first } from "lodash/fp"
import { classNames } from "../utils/utils"

export const handleBrokenImage = (event, src) => {
  event.target.src = src
  event.target.srcset = ""
}

const resizeUrl =
  "https://static.store.app/cdn-cgi/image/width=%WIDTH%,quality=%QUALITY%,format=auto/%SRC%"

const imageResizerConfig = {
  resizeUrl: resizeUrl,
  cfSrcSetUrl: resizeUrl + " %WIDTH%w",
  sizesMap: {
    sm: 256,
    md: 512,
    lg: 768,
    xl: 1024,
  },
}

export default function CfImage({
  defaultWidth,
  maxWidth: maxWidthProp,
  src: srcProp = "",
  className,
  alt,
  onClick = () => {},
  quality = 85,
  sizesMap = imageResizerConfig.sizesMap,
  ...rest
}) {
  let src = srcProp.replace(
    "https://store-app-images.s3.us-east-1.amazonaws.com/",
    "",
  )

  const srcSet = []
  const srcSetWidths = Object.values(sizesMap).sort((a, b) => a - b)
  const maxWidth = maxWidthProp || first(srcSetWidths.slice(-1))

  srcSetWidths.forEach((w) => {
    if (!maxWidth || w <= maxWidth) {
      srcSet.push(
        imageResizerConfig.cfSrcSetUrl
          .replace(/%WIDTH%/g, w)
          .replace("%SRC%", src)
          .replace("%QUALITY%", quality),
      )
    }
  })

  if (!defaultWidth || defaultWidth > maxWidth) {
    defaultWidth = maxWidth
  }

  let srcMain = imageResizerConfig.resizeUrl
    .replace(/%WIDTH%/g, defaultWidth)
    .replace("%SRC%", src)
    .replace("%QUALITY%", quality)

  const sizes = Object.entries(sizesMap)
    .filter(([k, v]) => v <= maxWidth)
    .map(([k, v]) => {
      if (v === (maxWidth || defaultWidth)) return ` ${v}px`
      return `(max-width: ${sizeToPx(k)}px) ${v}px`
    })
    .join(",")

  return (
    <img
      {...rest}
      className={classNames(className, "bg-gray-50")}
      src={
        !srcProp
          ? "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" // transparent pixel
          : srcMain
      }
      srcSet={!src ? undefined : srcSet.join(",")}
      alt={!srcProp ? "" : alt}
      onClick={onClick}
      sizes={!src ? undefined : sizes}
      onError={(event) => handleBrokenImage(event, srcProp)}
    />
  )
}

const sizeToPx = (size) => {
  return {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  }[size]
}
