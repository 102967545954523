import { range } from "lodash/fp"
import { v4 as uuidv4 } from "uuid"

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ")
}

/* eslint-disable no-useless-escape */
export const parseUrl = (url) => {
  var m = url.match(
      /^((?:([^:\/?#]+:)(?:\/\/))?((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?))?([^?#]*)(\?[^#]*)?(#.*)?$/,
    ),
    r = {
      hash: m[10] || "", // #asd
      host: m[3] || "", // localhost:257
      hostname: m[6] || "", // localhost
      href: m[0] || "", // http://username:password@localhost:257/deploy/?asd=asd#asd
      origin: m[1] || "", // http://username:password@localhost:257
      pathname: m[8] || (m[1] ? "/" : ""), // /deploy/
      port: m[7] || "", // 257
      protocol: m[2] || "", // http:
      search: m[9] || "", // ?asd=asd
      username: m[4] || "", // username
      password: m[5] || "", // password
    }
  if (r.protocol.length === 2) {
    r.protocol = "file:///" + r.protocol.toUpperCase()
    r.origin = r.protocol + "//" + r.host
  }
  r.href = r.origin + r.pathname + r.search + r.hash
  return r
}

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand("copy", true, text)
  }
}

export function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}

export const getDomainWithoutSubdomain = (url) => {
  const urlParts = new URL(url).hostname.split(".")

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".")
}

var rank = {
  TOO_SHORT: (score) => ({
    score,
    text: "Too short",
    color: "bg-gradient-to-r from-red-600 to-red-500",
    emoji: "🥴",
  }),
  WEAK: (score) => ({
    score,
    text: "A bit weak",
    color: "bg-gradient-to-r from-yellow-600 to-yellow-500",
    emoji: "🤨",
  }),
  MEDIUM: (score) => ({
    score,
    text: "Could be better",
    color: "bg-gradient-to-r from-yellow-500 to-yellow-400",
    emoji: "😮",
  }),
  STRONG: (score) => ({
    score,
    text: "Strong password",
    color: "bg-gradient-to-r from-green-700 to-green-500",
    emoji: "😲",
  }),
  VERY_STRONG: (score) => ({
    score,
    text: "Wunderkind",
    color: "bg-gradient-to-r from-green-600 to-green-400",
    emoji: "🤓",
  }),
}

export const rankPassword = (password) => {
  var upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    special = /[^A-Za-z0-9]/,
    minLength = 6,
    score = 0

  // Increment the score for each of these conditions
  if (upper.test(password)) score++
  if (lower.test(password)) score++
  if (number.test(password)) score++
  if (special.test(password)) score++

  if (password.length > minLength) {
    // Increment the score for each additional character (weighted by 1.25)
    score += Math.floor((password.length - minLength) / 1.25)
  }

  // Return a ranking based on the calculated score
  if (password.length < minLength) return rank.TOO_SHORT(score)
  if (score < 3) return rank.WEAK(score)
  if (score < 4) return rank.MEDIUM(score)
  if (score < 5) return rank.STRONG(score)
  return rank.VERY_STRONG(score)
}

export const getStarWidth = (rating, avg_rating) => {
  if (Math.floor(avg_rating) !== rating) return 100
  const w = (avg_rating % 1).toFixed(2) * 100
  if (w === 0 && w <= 10) return 0
  if (w > 10 && w <= 25) return 25
  return w
}

export const handleBrokenImage = (event) => {
  event.target.src = `${process.env.PUBLIC_URL}/maskable1024.png`
}

export const isAlphanumeric = (str) =>
  /^[a-zA-Z0-9]+([ ]{1}[a-zA-Z0-9]+)*$/.test(str)

export const loadingIds = (n) => range(0)(n).map(() => uuidv4())

export const PwaTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This is an installable web app
  </div>
)

export const ClaimedAppTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This app's listing has been claimed by the owner
  </div>
)

export const UnClaimedAppTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This app has not yet been claimed by the owner
  </div>
)

export const MobilePreviewsTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    We recommend using a 19.5:9 aspect ratio for mobile previews
  </div>
)

export const DesktopPreviewsTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    We recommend using a 4:3 aspect ratio for desktop previews
  </div>
)

export const LighthouseTooltipContent = () => (
  <div className="w-60 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This score is based on a combination of this app's performance,
    accessibility, SEO, and best practices. Click "View Full Report" to learn
    more.
  </div>
)
