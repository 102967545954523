import { useDispatch } from "react-redux"
import { analyticsTrack } from "../../../api/analytics"
import { useSkipFirstRenderEffect } from "../../hooks/useSkipFirstRenderEffect"

const TrackMenu = ({ open, id }) => {
  const dispatch = useDispatch()

  useSkipFirstRenderEffect(() => {
    dispatch(
      analyticsTrack({
        eventObject: "navigation",
        eventAction: "menu",
        contextualProps: {
          action: open ? "open" : "close",
          menu_id: id,
        },
      }),
    )
  }, [dispatch, open])

  return null
}

export default TrackMenu
