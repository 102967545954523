import {
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { flow, filter, find, keyBy, map } from "lodash/fp"
import { createRequestStatusSelector } from "../util"
import {
  authActions,
  login,
  renewUserSession,
  signUp,
} from "../auth/auth.slice"
import { BASE_URL, authApi } from "../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const joinDevWaitlist = createAsyncThunk(
  `accounts/join-dev-waitlist`,
  async (values) => await authApi.post(`${BASE_URL}/waitlist/dev`, values),
)

const accounts = JSON.parse(localStorage.getItem("accounts")) || []
const accountsAdapter = createEntityAdapter()
const initialState = accountsAdapter.getInitialState({
  ids: map("id")(accounts),
  entities: keyBy("id")(accounts),
  requests: {},
})
export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(authActions.logout.fulfilled, (state, action) => {
        // nuke accounts on logout
        localStorage.removeItem("accounts")
        accountsAdapter.removeAll(state)
      })
      .addMatcher(
        isFulfilled(login, signUp, renewUserSession),
        (state, action) => {
          const { accounts = [] } = action.payload

          // save accounts to local storage
          // to rehydrate on refresh
          localStorage.setItem("accounts", JSON.stringify(accounts))

          accountsAdapter.setMany(state, accounts)
        },
      )
      /* Request status handlers */
      .addMatcher(isPending(login, signUp), (state, action) => {
        const status = { status: "pending", error: null }
        state.requests.all = status
      })
      .addMatcher(isFulfilled(login, signUp), (state, action) => {
        const status = { status: "fufilled", error: null }
        state.requests.all = status
      })
      .addMatcher(isRejected(login, signUp), (state, action) => {
        const status = { status: "rejected", error: action.error }
        state.requests.all = status
      })
  },
})

// exports
export const accountsActions = { ...accountsSlice.actions }
export const accountsReducer = accountsSlice.reducer

// selectors
const { zipWithRequestStatus } = createRequestStatusSelector(accountsSlice.name)
export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectEntities: selectAccountEntities,
} = accountsAdapter.getSelectors((state) => state.accounts)

export const selectAccountsByOwner = () => (state) => {
  const userId = state.auth.user?.id

  return flow(
    selectAllAccounts,
    filter("owner", userId),
    zipWithRequestStatus(state),
  )(state)
}

export const selectAccountByType =
  (accountType = "developer") =>
  (state) => {
    const userId = state.auth.user?.id

    return flow(
      selectAllAccounts,
      filter("owner", userId),
      find(["account_type", accountType]),
      zipWithRequestStatus(state),
    )(state)
  }

export const selectActiveUserAccount = (state) => {
  const userId = state.auth.user?.id

  return flow(
    selectAllAccounts,
    filter("owner", userId),
    find(["account_type", "user"]),
    zipWithRequestStatus(state),
  )(state)
}
