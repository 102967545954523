import React, { useRef, useState } from "react"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { Dialog } from "@headlessui/react"
import FloatingLabelTextField from "../inputs/FloatingLabelTextField"
import { XMarkIcon } from "@heroicons/react/24/outline"
import TrackModal from "../track/TrackModal"
import { DesktopModal, MobileModal } from "./Modal"
import { useIsMobile } from "../../../features/home/hooks/useIsMobile"

const SubmitButton = ({ step }) => (
  <button
    type="submit"
    className="flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-transparent"
  >
    {step.button.children}
  </button>
)

const DismissButton = ({ step, handleClose }) => (
  <button
    type="button"
    onClick={handleClose}
    className="flex w-full max-w-2xl items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-blue-500 hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    {step.button.children}
  </button>
)

const SubmitFormModal = ({
  id,
  children,
  steps,
  initialValues = {},
  onSubmit,
}) => {
  const formRef = useRef()
  const Modal = useIsMobile() ? MobileModal : DesktopModal
  const [open, setOpen] = useState(false)

  const [s1, s2] = steps
  const current = (success) => (success ? s2 : s1)

  const validationSchema = Yup.object(
    s1.fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: curr.yup,
      }),
      {},
    ),
  )

  const initialStatus = {
    success: false,
    msg: "",
  }

  const handleClose = () => {
    setOpen(false)
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  return (
    <>
      {React.Children.map(children, (el) =>
        React.cloneElement(el, { onClick: () => setOpen(true) }),
      )}
      <Modal open={open} handleClose={handleClose}>
        <Formik
          innerRef={formRef}
          initialStatus={initialStatus}
          initialValues={Object.assign(
            s1.fields.reduce((acc, curr) => ({
              ...acc,
              [curr.name]: curr.type === "checkbox" ? false : "",
            })),
            initialValues,
          )}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ status: { success, msg }, resetForm }) => (
            <>
              <TrackModal
                id={id}
                open={open}
                step={success ? "form_success" : "form_input"}
              />

              <button
                type="button"
                onClick={() => {
                  setOpen(false)
                  resetForm()
                }}
                className="absolute right-4 top-4 z-20 disabled:cursor-not-allowed disabled:opacity-25"
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </button>
              <Form className="mx-auto max-w-sm">
                <div className="flex flex-col items-center justify-center space-y-6">
                  <div className="flex flex-col items-center text-center">
                    {current(success).icon}

                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900"
                    >
                      {current(success).title}
                    </Dialog.Title>
                    <Dialog.Description className="mt-2 text-sm text-gray-500">
                      {current(success).description}
                    </Dialog.Description>
                  </div>
                  <div className="w-full space-y-2">
                    {current(success).fields.map(
                      ({ Component = FloatingLabelTextField, ...f }) => (
                        <Component
                          key={f.name}
                          name={f.name}
                          label={f.label}
                          type={f.type}
                          autoComplete={f.autoComplete || undefined}
                          placeholder={f.placeholder || " "}
                          rows={f.rows || undefined}
                          disabled={f.disabled}
                        />
                      ),
                    )}
                  </div>
                  {success ? (
                    <DismissButton
                      step={current(success)}
                      handleClose={() => {
                        setOpen(false)
                        // make sure modal closes before reset
                        // 800ms ~= 2x 300ms transition
                        setTimeout(
                          () => resetForm({ status: initialStatus }),
                          800,
                        )
                      }}
                    />
                  ) : (
                    <SubmitButton step={current(success)} />
                  )}
                  {!success && <div>{msg}</div>}
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default SubmitFormModal
