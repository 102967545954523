import { createAsyncThunk } from "@reduxjs/toolkit"
import { BASE_URL, api } from "./api"
import { omitBy, isEmpty } from "lodash/fp"
import { useSearchParams } from "react-router-dom"
import { getCachedAnonId, getCachedUserId } from "../api/analytics"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

export const useShareLink = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const shareParam = searchParams.get("share")
  useEffect(() => {
    if (!shareParam) return
    dispatch(
      sendShareClick({
        user_id: getCachedUserId(),
        anon_id: getCachedAnonId(),
        hash: shareParam,
        referrer: document.referrer,
      }),
    )
  }, [shareParam, dispatch, searchParams])
}

export const sendNewShareLink = createAsyncThunk(
  `share/create`,
  async ({ link, link_type, user_id, anon_id, hash }) => {
    return await api.post(
      `${BASE_URL}/share/create`,
      omitBy(isEmpty)({
        link,
        link_type: link_type,
        user_id: user_id,
        anon_id: anon_id,
        hash: hash,
      }),
    )
  },
)

export const sendShareClick = createAsyncThunk(
  `share/create`,
  async ({ user_id, anon_id, hash, referrer }) => {
    return await api.post(
      `${BASE_URL}/share/click`,
      omitBy(isEmpty)({
        user_id,
        anon_id,
        hash,
        referrer,
      }),
    )
  },
)
