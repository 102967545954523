import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { api, BASE_URL } from "../api"

export const listAuditsByApp = createAsyncThunk(
  `audits/get`,
  async (appId) => await api.get(`${BASE_URL}/apps/${appId}/audits`),
)

const isAPendingAction = isPending(listAuditsByApp)
const isAFufilledAction = isFulfilled(listAuditsByApp)
const isARejectedAction = isRejected(listAuditsByApp)

const auditsAdapter = createEntityAdapter()
const initialState = auditsAdapter.getInitialState({
  requests: {},
})
export const auditsSlice = createSlice({
  name: "audits",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(listAuditsByApp.fulfilled, (state, action) => {
        const audits = action.payload
        auditsAdapter.setMany(state, audits)
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        if (listAuditsByApp.pending.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isAFufilledAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        if (listAuditsByApp.fulfilled.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        if (listAuditsByApp.rejected.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
  },
})

// exports
export const auditsActions = { ...auditsSlice.actions }
export const auditsReducer = auditsSlice.reducer

// selectors
export const {
  selectAll: selectAllAudits,
  selectById: selectAuditById,
  selectEntities: selectAuditEntities,
} = auditsAdapter.getSelectors((state) => state.audits)

export const selectRecentAuditByAppId = (appId) => (state) => {
  const audits = selectAllAudits(state)
    .filter((a) => a.app_id === appId)
    .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
    .find(() => true)
  return { data: audits }
}
