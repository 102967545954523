import { CheckIcon } from "@heroicons/react/20/solid"

export const FormSuccessIcon = () => (
  <div className="block h-24 w-full mt-8">
    <div className="m-auto mt-0 h-16 w-16 bg-green-100 rounded-full" />
    <CheckIcon
      className="m-auto -mt-12 h-8 w-8 fill-green-500 rounded-full"
      aria-hidden="true"
    />
  </div>
)
