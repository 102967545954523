import { isRejected, isFulfilled } from "@reduxjs/toolkit"
import { notify } from "reapop"
import {
  analyticsIdentify,
  analyticsPage,
  analyticsTrack,
} from "../../../api/analytics"
import {
  checkUsername,
  login,
  logout,
  renewUserSession,
  signUp,
} from "../../../api/auth/auth.slice"
import { getVerifyStatus } from "../../../api/claimedApps/claimedApps.slice"
import { deleteList } from "../../../api/lists/lists.slice"
import { getOverride } from "../../../api/overrides/overrides.slice"

/**
 * Extract messages from async thunks and dispatch notifications.
 */
export const reapopNotificationMiddleware = (store) => (next) => (action) => {
  const isBlacklisted = isRejected(
    getVerifyStatus,
    analyticsTrack,
    analyticsPage,
    analyticsIdentify,
    getOverride,
    renewUserSession,
    logout,
    checkUsername,
    login,
    signUp,
  )

  const isWhitelisted = isFulfilled(deleteList)

  if (isWhitelisted(action)) {
    const message = action.payload?.message
    const msgHTML = `
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">Successfully deleted list!</p>
          </div>
        </div>
      </div>
    </div>`
    if (message) {
      store.dispatch(
        notify({
          msgHTML,
          dismissAfter: 8000,
          dismissible: true,
        }),
      )
    }
  }

  if (isBlacklisted(action)) return next(action)

  if (action.type && isRejected(action)) {
    const statusCode = action.error?.code
    const message = action.meta?.rejectedWithValue
      ? action.payload?.message
      : action.error?.message

    if (message) {
      // optimization: using string template here vs invoking
      //  `renderToString` on on each action (see below)
      const msgHTML = (content) => `
      <div class="relative flex items-center m-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="-ml-4 w-6 h-6 text-red-300">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path>
        </svg>
        <span class="text-gray-600 ml-2">${content}</span>
      </div>`

      const maybeCustomError = customErrorMsg(statusCode)
      if (maybeCustomError) {
        store.dispatch(
          notify({
            message: msgHTML(maybeCustomError),
            dismissAfter: 8000,
            dismissible: true,
            allowHTML: true,
          }),
        )
      } else {
        store.dispatch(
          notify({
            message: msgHTML(message),
            dismissAfter: 8000,
            dismissible: true,
            allowHTML: true,
          }),
        )
      }
    }
  }

  return next(action)
}

export default reapopNotificationMiddleware

function customErrorMsg(statusCode) {
  return (
    {
      404: "Page not found",
    }[statusCode] || null
  )
}

/**
 * Regenerate HTML (paste above)
 * to change styling of error msg toast
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { renderToString } from "react-dom/server"

const html = renderToString(
  <div className="relative flex items-start m-2">
    <ExclamationCircleIcon className="absolute top-1 -left-3 w-6 h-6 text-red-300" />
    <span className="text-gray-600 ml-6">{message}</span>
  </div>
)
 */
