import { ArrowUpRightIcon } from "../assets/CustomIcons"
import { useDispatch } from "react-redux"
import { analyticsTrack } from "../../api/analytics"

const handleClick = (event, dispatch, app, launchLink) => {
  event.preventDefault()
  event.stopPropagation()

  window.open(launchLink, "_blank", "noopener noreferrer")
  if (app?.id) {
    dispatch(
      analyticsTrack({
        eventObject: "app",
        eventAction: "launch",
        appId: app.id,
        contextualProps: {
          platform: "web_app",
        },
      }),
    )
  }
}

const LaunchButton = ({ variant, app, content }) => {
  const dispatch = useDispatch()

  const maybeLaunchURL = content?.links?.install?.find(
    (l) => l.type === "web_app",
  )?.url

  const appURL = app?.url
  return (
    <button
      onClick={(e) => handleClick(e, dispatch, app, maybeLaunchURL || appURL)}
      className={classes[variant].buttonStyle}
    >
      Launch
      <ArrowUpRightIcon
        className={classes[variant].iconStyle}
        aria-hidden="true"
      />
    </button>
  )
}

const classes = {
  darkBlueFull: {
    buttonStyle:
      "relative ml-1 flex flex-row items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none",
    iconStyle: "ml-1 h-5 w-5",
  },

  lightBlueFull: {
    buttonStyle:
      "relative ml-1 inline-flex rounded-md border border-transparent bg-blue-50 px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-100 focus:outline-none",
    iconStyle: "ml-1 h-5 w-5",
  },
  lightBlueSmall: {
    buttonStyle:
      "relative inline-flex rounded-md border border-transparent bg-blue-50 px-3 py-[6px] text-sm font-medium text-blue-500 hover:bg-blue-100 focus:outline-none",
    iconStyle: "hidden",
  },
}

export default LaunchButton
