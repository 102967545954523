import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { authActions } from "../../../api/auth/auth.slice"
import { classNames } from "../../utils/utils"
import { first, capitalize } from "lodash/fp"
import { selectAccountByType } from "../../../api/accounts/accounts.slice"
import TrackMenu from "../track/TrackMenu"

const userNavigation = (username) => [
  {
    name: "Your Profile",
    href: `/user/${
      username || JSON.parse(localStorage.getItem("user"))?.username
    }`,
    permissions: "user",
  },
  {
    name: "Dev Dashboard",
    href: "/dashboard/developer",
    permissions: "developer",
  },
  {
    name: "Change Password",
    href: "/dashboard/user/settings",
    permissions: "user",
  },
]

const ProfileDropdown = ({ user }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(authActions.logout())
    navigate("/")
  }

  const { data: account } = useSelector(selectAccountByType("developer"))

  const hasPermissions = (item) => {
    if (
      item.permissions === "developer" &&
      (!account || account.account_type !== "developer")
    ) {
      return false
    }
    return true
  }

  const initials = `${capitalize(first(user.first_name))}${capitalize(
    first(user.last_name),
  )}`

  return (
    <Menu as="div" className="relative ml-5 flex-shrink-0">
      {({ open }) => (
        <>
          <TrackMenu open={open} id="profile_menu" />
          <div>
            <Menu.Button className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              <span className="sr-only">Open user menu</span>
              <Avatar initials={initials} />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {userNavigation(user?.username)
                .filter(hasPermissions)
                .map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block py-2 px-4 text-sm text-gray-700",
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              <Menu.Item key="sign-out">
                {({ active }) => (
                  <button
                    onClick={handleLogout}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block w-full py-2 px-4 text-left text-sm text-gray-700",
                    )}
                  >
                    Log out
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
export default ProfileDropdown

function Avatar({ initials }) {
  return (
    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
      <span className="text-sm font-medium leading-none text-white">
        {initials}
      </span>
    </span>
  )
}
