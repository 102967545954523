import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

export const LocationHistoryContext = createContext({})

const LocationHistoryProvider = ({ children }) => {
    const type = useNavigationType()
    const [locationHistoryStack, setLocationHistoryStack] = useState([])
    const { pathname } = useLocation()
    
    useEffect(() => {
        if (type === "POP") {
            setLocationHistoryStack(locationHistoryStack.slice(0, locationHistoryStack.length - 1))
        } else if (type === "PUSH") {
            setLocationHistoryStack([...locationHistoryStack, pathname])
        } else {
            setLocationHistoryStack([...locationHistoryStack.slice(0, locationHistoryStack.length - 1), pathname])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    
    return (
        <LocationHistoryContext.Provider value={locationHistoryStack.length > 0}>
        {children}
        </LocationHistoryContext.Provider>
    )
}

export default LocationHistoryProvider
