import { useMemo } from "react"
import { useSelector } from "react-redux"
import { willExpireIn } from "../../api/api"
import { selectAuthUser } from "../../api/auth/auth.slice"
import { useAuth } from "./useAuth"

export const useIsLoggedIn = () => {
  const user = useSelector(selectAuthUser)
  const { accessToken, refreshToken, refreshTokenExpiresAt } = useAuth()

  const loggedIn = !(
    !accessToken ||
    !refreshToken ||
    willExpireIn(refreshTokenExpiresAt, 0) ||
    !user?.id
  )
  return useMemo(() => loggedIn, [loggedIn])
}
