import React from "react"
import * as Yup from "yup"
import { DeveloperIcon } from "../../../shared/assets/CustomIcons"
import InsetLabelTextArea from "../inputs/InsetLabelTextArea"
import DisabledTextField from "../formik/DisabledTextField"
import { FormSuccessIcon } from "./modals.utils"
import SubmitFormModal from "./SubmitFormModal"
import {
  joinDevWaitlist,
  selectAccountsByOwner,
} from "../../../api/accounts/accounts.slice"
import { useDispatch, useSelector } from "react-redux"

const steps = [
  {
    icon: <DeveloperIcon className="m-4 h-7 w-7" alt="Join Waitlist Icon" />,
    title: "Request Developer Account",
    description:
      "Request developer access to add, claim, and manage your listings on store.app!",
    fields: [
      {
        name: "email",
        type: "text",
        autoComplete: "email",
        yup: Yup.string().email().required(),
        disabled: true,
        Component: DisabledTextField,
      },
      {
        name: "applink",
        type: "text",
        label: "App Url (web, twitter, etc)",
        yup: Yup.string().url(),
      },
      {
        name: "about",
        type: "textarea",
        yup: Yup.string().required(),
        Component: InsetLabelTextArea,
        placeholder:
          "Tell us about yourself\n(link to socials, github or others)",
        rows: 4,
      },
    ],
    button: {
      children: "Join Waitlist",
      color: `primary`,
      variant: `contained`,
    },
  },
  {
    icon: <FormSuccessIcon />,
    title: "Thanks!",
    description: "We'll be in touch with more details soon.",
    fields: [],
    button: {
      children: "Dismiss",
      color: `secondary`,
      variant: `text`,
    },
  },
]

const DevAccessModal = ({ user, openButton = null }) => {
  const dispatch = useDispatch()
  const { data: accounts } = useSelector(selectAccountsByOwner())
  const isDev =
    accounts?.length &&
    accounts.find((account) => account.account_type === "developer")

  if (!user || !user.email) return null
  if (isDev) return null

  const initialValues = {
    email: user.email,
    applink: "",
    about: "",
  }

  const onSubmit = async (values, config) => {
    config.setSubmitting(true)

    let resp
    try {
      resp = await dispatch(joinDevWaitlist(values)).unwrap()
      config.resetForm({ status: { success: true, msg: "" } })
    } catch (error) {
      config.resetForm({ success: false, msg: "Failed. Try again." })
    }

    config.setSubmitting(false)

    return resp
  }

  return (
    <SubmitFormModal
      id="request-developer-access"
      steps={steps}
      initialValues={initialValues}
      user={user}
      onSubmit={onSubmit}
    >
      {openButton ? (
        openButton
      ) : (
        <button
          type="button"
          className="inline-flex items-center rounded-md border-none bg-none px-4 py-2 text-sm font-medium text-gray-500 hover:bg-neutral-100 focus:outline-none focus:ring-0"
        >
          Request Developer Access
        </button>
      )}
    </SubmitFormModal>
  )
}

export default DevAccessModal
