import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
  createAction,
} from "@reduxjs/toolkit"
import { createRequestStatusSelector } from "../util"
import { authApi, BASE_URL } from "../api"
import { authActions } from "../auth/auth.slice"

export const listClaimedApps = createAsyncThunk(
  `claimedApps/list`,
  async (accountId) =>
    await authApi.get(`${BASE_URL}/accounts/${accountId}/apps`),
)

export const createPendingClaim = createAsyncThunk(
  `claimedApps/verify/create`,
  async (appId) => await authApi.post(`${BASE_URL}/apps/${appId}/verify`),
)

export const getVerifyStatus = createAsyncThunk(
  `claimedApps/verify/status`,
  async (appId) => await authApi.get(`${BASE_URL}/apps/${appId}/verify`),
)

export const onVerifySuccess = createAction(`claimedApps/verify/success`)

const isAPendingAction = isPending(listClaimedApps)
const isAFufilledAction = isFulfilled(listClaimedApps)
const isARejectedAction = isRejected(listClaimedApps)

const claimedAppsAdapter = createEntityAdapter({
  // Using app_id ~> since use case here is ~> many apps::one account
  selectId: (claimedApp) => claimedApp.app_id,
})
const initialState = claimedAppsAdapter.getInitialState({
  requests: {},
})
export const claimedAppsSlice = createSlice({
  name: "claimedApps",
  initialState,
  reducers: {
    setVerificationStatus: (state, action) => {
      const claimedApp = action.payload
      claimedAppsAdapter.setOne(state, claimedApp)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listClaimedApps.fulfilled, (state, action) => {
        const claimedApps = action.payload
        claimedAppsAdapter.setMany(state, claimedApps)
      })
      .addCase(createPendingClaim.fulfilled, (state, action) => {
        const claim = action.payload
        claimedAppsAdapter.setOne(state, claim)
      })
      .addCase(authActions.logout, (state, action) => {
        // nuke claimed apps on logout
        claimedAppsAdapter.removeAll(state)
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        if (listClaimedApps.pending.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isAFufilledAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        if (listClaimedApps.fulfilled.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        if (listClaimedApps.rejected.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
  },
})

// exports
export const claimedAppsActions = { ...claimedAppsSlice.actions }
export const claimedAppsReducer = claimedAppsSlice.reducer

// selectors
export const selectRequestStatus = createRequestStatusSelector(
  claimedAppsSlice.name,
)
export const {
  selectAll: selectAllCaimedApps,
  selectById: selectClaimedAppsById,
  selectEntities: selectClaimedAppsEntities,
  selectIds: selectClaimedAppIds,
} = claimedAppsAdapter.getSelectors((state) => state.claimedApps)
