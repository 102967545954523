import { useEffect } from "react"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { mapValues } from "lodash/fp"
import { BASE_URL } from "./api"
import { useLocation } from "react-router-dom"

// ACTIONS - Async Thunks
export const analyticsTrack = createAsyncThunk(
  `analytics/track`,
  async (
    { eventObject, eventAction, appId, contextualProps = {} },
    { getState },
  ) => {
    const [accessToken, isValid] = getAccessToken(getState())
    var myHeaders = new Headers()

    // optimization - body has abbreviated key values
    let body = {
      o: eventObject,
      a: eventAction,
    }

    if (appId) {
      body.ap = appId
    }

    if (Object.keys(contextualProps).length > 0) {
      body.p = mapValues(String)(contextualProps)
    }

    if (isValid) {
      myHeaders.append("Authorization", `Bearer ${accessToken}`)
    } else {
      // look for a cached userId
      const maybeUserId = getCachedUserId()
      if (!!maybeUserId) {
        body.us = maybeUserId
      }
    }

    body.an = getCachedAnonId()

    var opts = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    }

    const promise = await fetch(`${BASE_URL}/a/t`, opts)

    if (promise.ok) {
      return promise.json()
    }

    return ""
  },
)

export const analyticsPage = createAsyncThunk(
  `analytics/page`,
  async ({ path, referrer, search, title, url }, { getState }) => {
    const [accessToken, isValid] = getAccessToken(getState())
    var myHeaders = new Headers()

    // optimization - body has abbreviated key values
    let body = {
      p: path,
      r: referrer,
      s: search,
      t: title,
      u: url,
    }

    if (isValid) {
      myHeaders.append("Authorization", `Bearer ${accessToken}`)
    } else {
      // look for a cached userId
      const maybeUserId = getCachedUserId()
      if (!!maybeUserId) {
        body.us = maybeUserId
      }
    }

    body.an = getCachedAnonId()

    const promise = await fetch(`${BASE_URL}/a/p`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    })

    if (promise.ok) {
      return promise.json()
    }

    return ""
  },
)

export const analyticsIdentify = createAsyncThunk(
  `analytics/identify`,
  async (_, { getState }) => {
    const [accessToken, isValid] = getAccessToken(getState())
    var myHeaders = new Headers()

    let body = {}

    if (isValid) {
      myHeaders.append("Authorization", `Bearer ${accessToken}`)
    } else {
      // look for a cached userId
      const maybeUserId = getCachedUserId()
      if (!!maybeUserId) {
        body.us = maybeUserId
      }
    }

    body.an = getCachedAnonId()

    const promise = await fetch(`${BASE_URL}/a/i`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    })

    if (promise.ok) {
      return promise.json()
    }

    return ""
  },
)

// HOOKS
export const usePageAnalytics = (sitePath) => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (!location) return

    dispatch(analyticsPage(pageDefaults(location)))
  }, [dispatch, location])
  return []
}

// utils
const getAccessToken = (state) => {
  const { accessToken, expiresAt } = state.auth
  const isValid = !!accessToken && new Date(expiresAt) > new Date()
  return [accessToken, isValid]
}

export const getCachedUserId = () => JSON.parse(localStorage.getItem("_userId"))
export const getCachedAnonId = () => {
  const maybeAnonId = JSON.parse(localStorage.getItem("_anonId"))
  if (!!maybeAnonId) {
    return maybeAnonId
  } else {
    const newAnonId = uuidv4()
    localStorage.setItem("_anonId", JSON.stringify(newAnonId))
    return newAnonId
  }
}

export function pageDefaults(location) {
  return {
    path: location.pathname,
    referrer: document.referrer,
    search: location.search,
    title: document.title,
    url: getUrl(location.search),
  }
}

function getUrl(search) {
  var url = window.location.href
  var i = url.indexOf("#")
  return i === -1 ? url : url.slice(0, i)
}
