import { useEffect, useRef } from "react"

export function useTimeout(cb, delay) {
  const tRef = useRef()
  const cbRef = useRef(cb)

  useEffect(() => {
    cbRef.current = cb
  }, [cb])

  useEffect(() => {
    if (typeof delay === "number") {
      tRef.current = setTimeout(() => cbRef.current(), delay)

      return () => clearTimeout(tRef.current)
    }
  }, [delay])

  // To clear manually if needed
  return tRef
}
