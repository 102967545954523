import { Helmet } from "react-helmet-async"
import { matchRoutes, useLocation } from "react-router-dom"
import { getMaskableFallbackIcon } from "../../api/util"
import { routes } from "../../routes"
import { categories } from "../../features/home/constants/categories"
import { useSelector } from "react-redux"
import { selectAllApps } from "../../api/apps/apps.slice"
import { first, merge } from "lodash/fp"
import { selectAllLists } from "../../api/lists/lists.slice"

export default function SEO() {
  const { pathname } = useLocation()

  const apps = useSelector(selectAllApps)
  const lists = useSelector(selectAllLists)

  let matches = matchRoutes(routes, pathname)
  const match = matches.find((m) => m.route.index || !m.route.children)

  const defaults = {
    title: "Store.app",
    description: "The everything app store",
    twitter: {
      card: "summary_large_image",
      description: undefined, // set in overrides below
      title: undefined,
      image:
        "https://store-app-images.s3.amazonaws.com/link_previews/desktop_preview.png",
      imageWidth: "2720",
      imageHeight: "1188",
    },
    openGraph: {
      image:
        "https://store-app-images.s3.amazonaws.com/link_previews/mobile_preview_crop.png",
      imageWidth: "620",
      imageHeight: "740",
      type: "website",
      title: undefined,
      description: undefined,
      siteName: "store.app",
      url: window.location.href || "https://store.app",
    },
  }

  const fn = {
    "/": () => ({}),
    home: () => ({
      title: "Store.app - Home",
    }),
    foryou: () => ({
      title: "Store.app - For You",
    }),
    privacy: () => ({
      title: "Store.app - Privacy",
    }),
    ":sitePath": listingOverrides,
    // "/user/:username": {},
    "lists/:listId": listOverrides,
    "categories/:category": categoriesOverrides,
    "categories/:category/:subCategory": subcategoryOverrides,
    // "/dashboard/developer": {},
    // "/dashboard/developer/account": {},
    // "/dashboard/developer/settings": {},
    // "/dashboard/developer/:sitePath": {},
    // "/dashboard/user": {},
    // "/dashboard/user/account": {},
    // "/dashboard/user/settings": {},
    // "auth/signup": {},
    // "auth/login": {},
    // "auth/forgot-password": {},
    // "auth/reset-password": {},
    // "auth/forgot-password/success": {},
    // "lhreport/:appId/:auditId": {},
    // terms: {},
    // privacy: {},
  }[getRouteKey(match)]

  let data = defaults
  if (fn !== undefined) {
    const overrides = fn({ match, apps, lists })
    data = merge(defaults)(overrides)
  }

  const metas = [
    {
      name: "description",
      content: data.description,
    },
    {
      property: "og:image",
      content: data.openGraph.image || data.twitter.image,
    },
    {
      property: "og:image:width",
      content: `${data.openGraph.imageWidth || data.twitter.imageWidth}`,
    },
    {
      property: "og:image:height",
      content: `${data.openGraph.imageHeight || data.twitter.imageHeight}`,
    },
    {
      property: "og:type",
      content: data.openGraph.type,
    },
    {
      property: "og:title",
      content: data.openGraph.title || data.twitter.title || data.title,
    },
    {
      property: "og:description",
      content:
        data.openGraph.description ||
        data.twitter.description ||
        data.description,
    },
    {
      property: "og:site_name",
      content: data.openGraph.siteName,
    },
    {
      property: "og:url",
      content: data.openGraph.url,
    },
    {
      name: "twitter:card",
      content: data.twitter.card,
    },
    {
      name: "twitter:description",
      content:
        data.twitter.description ||
        data.openGraph.description ||
        data.description,
    },
    {
      name: "twitter:title",
      content: data.twitter.title || data.openGraph.title || data.title,
    },
    {
      name: "twitter:image",
      content: data.twitter.image || data.openGraph.image,
    },
    {
      name: "twitter:site",
      content: "@storedotapp",
    },
    {
      name: "twitter:creator",
      content: "@storedotapp",
    },
  ]

  // If we have keywords, then add it
  if (data.keywords) {
    metas.push({
      name: "keywords",
      content: data.keywords,
    })
  }

  return (
    <Helmet>
      <title>{data.title}</title>
      {metas.map((meta) => (
        <meta key={meta.name || meta.property} {...meta} />
      ))}
      {data.jsonLD && (
        <script type="application/ld+json">
          {JSON.stringify(data.jsonLD)}
        </script>
      )}
    </Helmet>
  )
}

const getRouteKey = (match) => {
  if (match.route.index) {
    return match.pathname
  }

  return match.route.path
}

const listingOverrides = ({ match, apps }) => {
  if (!match || !match.params) return {}
  const { sitePath } = match.params

  if (apps && apps.length) {
    const app = apps.find((a) => a.site_path === sitePath)

    const title = app?.content?.name
    const description = app?.content?.description
    const icon = getMaskableFallbackIcon(app?.content?.icons)

    if (icon && icon.sizes && icon.sizes.includes("x")) {
      const [width, height] = icon?.sizes?.split("x")

      return {
        title: `Store.app | ${app?.content?.name}`,
        description,
        twitter: {
          image: icon.src,
          imageWidth: width,
          imageHeight: height,
          card: "summary",
          description: description,
          title: title,
        },
        openGraph: {
          image: icon.src,
          imageWidth: "620",
          imageHeight: "740",
          title: title,
          description: description,
        },
        jsonLD: getStructuredData(app),
      }
    }
  }

  return {}
}

const categoriesOverrides = ({ match }) => {
  if (!match || !match.params) return {}
  const { category } = match.params

  if (category) {
    const cat = categories.find((c) => c.id === category)
    const name = cat?.name
    const title = `Store.app | ${name}`
    let description = `Discover the best ${name?.toLowerCase()} apps at Store.app.`
    const imageUrl = `https://store-app-images.s3.amazonaws.com/link_previews/${category}.png`

    if (cat?.id === "ai") {
      description = `Discover the best AI apps at Store.app.`
    }

    return {
      title,
      description,
      twitter: {
        image: imageUrl,
        imageWidth: 400,
        imageHeight: 400,
        card: "summary",
        description: description,
        title: title,
      },
      openGraph: {
        image: imageUrl,
        imageWidth: 400,
        imageHeight: 400,
        title: title,
        description: description,
      },
    }
  }

  return {}
}

const subcategoryOverrides = ({ match }) => {
  if (!match || !match.params) return {}
  const { category, subCategory } = match.params

  if (category && subCategory) {
    const cat = categories.find((c) => c.id === category)
    const sub = cat?.subCategories?.find((s) => s.id === subCategory)

    const title = `Store.app | ${cat?.name} - ${sub?.name}`
    let description = `Discover the best ${sub?.name?.toLowerCase()} apps at Store.app.`

    if (cat?.id === "games") {
      if (sub?.id === "boardgame") {
        description = `Discover the best ${sub?.name?.toLowerCase()}s at Store.app.`
      } else {
        description = `Discover the best ${sub?.name?.toLowerCase()} games at Store.app.`
      }
    }

    const imageUrl = `https://store-app-images.s3.amazonaws.com/link_previews/${category}_${subCategory}.png`
    return {
      title,
      description,
      twitter: {
        image: imageUrl,
        imageWidth: 400,
        imageHeight: 400,
        card: "summary",
        description: description,
        title: title,
      },
      openGraph: {
        image: imageUrl,
        imageWidth: 400,
        imageHeight: 400,
        title: title,
        description: description,
      },
    }
  }

  return {}
}

const listOverrides = ({ match, lists }) => {
  if (!match || !match.params) return {}
  const { listId } = match.params

  if (listId) {
    const listName = lists.find((l) => l.id === listId)?.name
    const title = `Store.app list: ${listName}`
    const description = `Discover the best apps at Store.app.`
    const imageUrl = `https://store-app-images.s3.amazonaws.com/link_previews/list.png`

    return {
      title: `Store.app | ${listName}`,
      description,
      twitter: {
        image: imageUrl,
        imageWidth: 400,
        imageHeight: 400,
        card: "summary",
        description: description,
        title: title,
      },
      openGraph: {
        image: imageUrl,
        imageWidth: 400,
        imageHeight: 400,
        title: title,
        description: description,
      },
    }
  }

  return {}
}

const getStructuredData = (app) => {
  const defaultAppType = "BrowserApplication"
  const supportedAppTypes = {
    games: "GameApplication",
    social: "SocialNetworkingApplication",
    travel: "TravelApplication",
    shopping: "ShoppingApplication",
    sports: "SportsApplication",
    lifestyle: "LifestyleApplication",
    business: "BusinessApplication",
    graphics_design: "DesignApplication",
    developer_tools: "DeveloperApplication",
    navigation: "DriverApplication",
    education: "EducationalApplication",
    health_fitness: "HealthApplication",
    finance: "FinanceApplication",
    crypto: "FinanceApplication",
    entertainment: "EntertainmentApplication",
    news: "MultimediaApplication",
    category: "HomeApplication",
    utilities: "UtilitiesApplication",
    reference: "ReferenceApplication",
  }

  const category = supportedAppTypes[first(app?.content?.categories)]
  const getScreenshots = (app) => {
    if (app?.content?.screenshots?.length)
      return app?.content?.screenshots?.map((s) => s.src)
    if (app?.content?.desktop_previews?.length)
      return app?.content?.desktop_previews?.map((s) => s.src)
    return []
  }

  return {
    "@context": "http://schema.org",
    "@type": ["SoftwareApplication", "WebApplication"],
    name: app?.content?.name,
    image: getMaskableFallbackIcon(app?.content?.icons)?.src,
    url: app?.url,
    applicationCategory: [category || defaultAppType],
    screenshot: getScreenshots(app),
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: app?.avg_rating,
      ratingCount: app?.review_count,
    },
  }
}
