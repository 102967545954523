export const createRequestStatusSelector = (resource) => {
  const selectRequestStatus = (state, requestId = "all") => {
    const reqInfo = state[resource].requests[requestId]
    const error = reqInfo?.error
    const isLoading = reqInfo?.status === "pending"
    const isIdle = !reqInfo
    return { isLoading, isIdle, error, status: reqInfo?.status }
  }

  const zipWithRequestStatus =
    (state, requestId = "all") =>
    (data) => ({
      data,
      ...selectRequestStatus(state, requestId),
    })

  return { selectRequestStatus, zipWithRequestStatus }
}

// Icon Utils
const splitSize = (icon) => icon.sizes?.split("x").map(Number)

export const isValidAspectRatio = (width, height) =>
  Math.abs(width / height - 1) < 0.1

export const isValidIconSize = (width, height) => width > 250 && height > 250

export const isValidIcon = (icon) => {
  if (!icon || !icon.sizes || !icon.sizes.includes("x")) return false
  const [width, height] = splitSize(icon)
  return isValidAspectRatio(width, height) && isValidIconSize(width, height)
}

const isCurrentLarger = (prev, curr) => {
  if (!prev) return true
  const [pWidth, pHeight] = splitSize(prev)
  const [cWidth, cHeight] = splitSize(curr)
  return cWidth > pWidth && cHeight > pHeight
}

export const createGetDefaultIcon = (purpose) => (icons) => {
  const predicateFn = {
    default: (p) => p !== "maskable",
    maskable: (p) => p === "maskable",
  }[purpose]

  return icons?.reduce((prev, curr) => {
    if (
      isValidIcon(curr) &&
      predicateFn(curr.purpose) &&
      isCurrentLarger(prev, curr)
    ) {
      return curr
    }
    return prev
  }, null)
}

export const getDefaultIcon = createGetDefaultIcon("default")
export const getDefaultMaskableIcon = createGetDefaultIcon("maskable")

export const getFallbackIcon = (icons) => {
  const defaultIcon = getDefaultIcon(icons)
  const fallbackIcon = !!icons?.length ? icons[0] : { src: "" }
  return defaultIcon || fallbackIcon
}

export const getMaskableFallbackIcon = (icons) => {
  const fallbackIcon = !!icons?.length ? icons[0] : { src: "" }
  return getDefaultMaskableIcon(icons) || getDefaultIcon(icons) || fallbackIcon
}
