import { useDispatch, useSelector } from "react-redux"
import { useRoutes } from "react-router-dom"
import NotificationsSystem, {
  wyboTheme,
  dismissNotification,
  setUpNotifications,
} from "reapop"
import { usePageAnalytics } from "./api/analytics"
import LocationHistoryContextProvider from "./locationHistoryContext"
import { useShareLink } from "./api/share_links"
import Intercom from "./shared/components/authentication/Intercom"
import { useMainLoader } from "./shared/hooks/useMainLoader"
import { routes } from "./routes"
import SEO from "./shared/components/SEO"

// run this function when your application starts before creating any notifications
setUpNotifications({
  defaultProps: {
    position: "bottom-right",
    dismissible: true,
  },
})

const App = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notifications)
  usePageAnalytics()
  useShareLink()
  useMainLoader()

  let element = useRoutes(routes)

  return (
    <div>
      <LocationHistoryContextProvider>
        {element}
        <NotificationsSystem
          notifications={notifications}
          // 3. Pass the function used to dismiss a notification.
          dismissNotification={(id) => dispatch(dismissNotification(id))}
          // 4. Pass a builtIn theme or a custom theme.
          theme={wyboTheme}
        />
        <Intercom />
        <SEO />
      </LocationHistoryContextProvider>
    </div>
  )
}

export default App
