import { store } from "../shared/redux/store"
import { authActions } from "./auth/auth.slice"
import { throttle } from "lodash/fp"

export const BASE_URL = process.env.REACT_APP_API_URL

export const createApi = ({ authenticated = false } = {}) => {
  const methods = ["GET", "POST", "PUT", "DELETE", "PATCH", "HEAD"]

  return methods.reduce((acc, method) => {
    acc[method.toLowerCase()] = request(method, authenticated)
    return acc
  }, {})
}

export const api = createApi()
export const authApi = createApi({ authenticated: true })

const handleRefresh = throttle(60 * 1000)((dispatch) => {
  return dispatch(authActions.renewUserSession())
})

export const expiresIn = (timestamp) =>
  (new Date(timestamp).valueOf() - new Date().valueOf()) / 1000

export const willExpireIn = (timestamp, seconds) =>
  expiresIn(timestamp) - seconds < 0

function request(method, addAuthHeaders = false) {
  return (
    url,
    body,
    requestOptions = {
      method,
      headers: new Headers(),
    },
  ) => {
    const isApiUrl = url.startsWith(BASE_URL)
    const isAuthenticatedRequest = isApiUrl && addAuthHeaders
    const { accessToken, expiresAt } = authInfo()

    // add mathod & default headers object
    requestOptions.method = method
    requestOptions.headers = requestOptions.headers || new Headers()

    // attach body if provided
    if (body) {
      if (body instanceof FormData) {
        requestOptions.body = body
      } else {
        requestOptions.headers.append("Content-Type", "application/json")
        requestOptions.body = JSON.stringify(body)
      }
    }

    // if auth token is expired, renew it before proceeding
    if (isAuthenticatedRequest && willExpireIn(expiresAt, 0)) {
      return handleRefresh(store.dispatch)
        .unwrap()
        .then(({ access_token }) => {
          // attach new auth token
          requestOptions.headers.append(
            "Authorization",
            `Bearer ${access_token}`,
          )
          return handleFetch(url, requestOptions)
        })
    }

    // attach auth headers if needed
    if (isAuthenticatedRequest) {
      requestOptions.headers.append("Authorization", `Bearer ${accessToken}`)
    }

    return handleFetch(url, requestOptions)
  }
}

function authInfo() {
  return store.getState().auth
}

async function handleFetch(url, opts) {
  const response = await fetch(url, opts)
  let text = await response.text()

  let res
  try {
    res = JSON.parse(text)
  } catch {
    res = text
  }

  if (!response.ok) {
    if (
      [401].includes(response.status) &&
      (!!opts.headers.get("Authorization") || url.includes("sessions/renew"))
    ) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      const logout = () => store.dispatch(authActions.logout())
      logout()
    }

    const message =
      res?.message || response.statusText || "Unknown server error"

    return Promise.reject({
      code: String(response.status),
      message,
    })
  }

  return res
}
