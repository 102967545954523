import { useSelector } from "react-redux"
import { isEmpty } from "lodash/fp"
import {
  Link,
  useLocation,
  useMatch,
  useParams,
  useNavigate,
} from "react-router-dom"
import ProfileDropdown from "./ProfileDropdown"
import { selectAuthUser } from "../../../api/auth/auth.slice"
import { classNames } from "../../utils/utils"
import { ArrowLeftIcon } from "@heroicons/react/20/solid"
import {
  LogoBlue,
  SearchIcon,
  StoreLogoDevBeta,
} from "../../assets/CustomIcons"
import DevAccessModal from "../modal/DevAccessModal"
import { LocationHistoryContext } from "../../../locationHistoryContext"
import { useIsMobile } from "../../../features/home/hooks/useIsMobile"
import { MobileXCategoriesBar } from "../../../features/home/components/CategoriesBar.mobile"
import { useEffect, useState } from "react"

const BackButton = (props) => {
  const navigate = useNavigate()
  const { classes, to = "/" } = props
  return (
    <LocationHistoryContext.Consumer>
      {(hasHistory) => {
        return (
          hasHistory && (
            <button
              onClick={() => navigate(to)}
              className="flex flex-row items-center text-sm text-gray-700"
            >
              <ArrowLeftIcon className={classes} aria-hidden="true" />
              <p className="ml-1 hidden sm:flex">
                {to !== -1 ? "Back to all apps" : "Back"}
              </p>
            </button>
          )
        )
      }}
    </LocationHistoryContext.Consumer>
  )
}

const DesktopHomeNavbar = ({ user, isDashboardView }) => {
  const location = useLocation()

  return (
    <div className="z-50 mx-auto">
      <div className="relative flex flex-col px-4 sm:flex-row lg:gap-8 lg:px-6">
        <div className="mx-auto flex w-12 flex-shrink-0 items-center justify-center lg:w-[186px]">
          <Link to="/">
            <div className="hidden lg:flex">
              <StoreLogoDevBeta
                className="block h-7 w-auto"
                alt="Store.app logo"
              />
            </div>
            <div className="lg:hidden">
              <LogoBlue className="block h-7 w-auto" alt="Store.app logo" />
            </div>
          </Link>
        </div>
        <div
          className={classNames(
            isDashboardView && "invisible",
            "min-w-0 flex-1 sm:px-8 lg:px-0",
          )}
        >
          {/* !!TODO: when adding back SearchCombobox, remove the sm modifier on sm:py-4 and sm:min-h-[38px] */}
          <div className="my-2 -ml-4 inline-flex w-full max-w-4xl cursor-not-allowed justify-between rounded-lg bg-gray-50 p-1 pl-4">
            <div className="inline-flex items-center text-gray-500">
              <p className="font-light">Search coming soon!</p>
            </div>
            <div className="rounded-lg bg-blue-500 p-3">
              <SearchIcon className="h-5 w-5 text-white" />
            </div>
          </div>
        </div>
        <div className="hidden items-center justify-end space-x-2 sm:flex">
          {!isEmpty(user) ? (
            <>
              <DevAccessModal user={user} />
              <ProfileDropdown user={user} />
            </>
          ) : (
            <>
              <Link to="/auth/login" state={{ from: location.pathname }}>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Login
                </button>
              </Link>
              <Link to="/auth/signup" state={{ from: location.pathname }}>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({
  isStaticHomeView,
  isHomeView,
  isCategorySelectedView,
  isListingView,
  isInstalled,
}) => {
  const { category: primary = "featured", subCategory } = useParams()

  const [isScrolled, setIsScrolled] = useState(false)
  const handleScroll = () => {
    const position = window.pageYOffset
    setIsScrolled(position > 0)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={classNames(
        "sticky top-0 z-20 border-b border-b-gray-200 bg-white",
        isScrolled && "shadow-md",
        // TODO: make unsticky when subcategories are available?
        // primary === "all" && "sticky top-0",
      )}
    >
      <div
        className={classNames(
          "grid grid-flow-row grid-cols-4 px-4",
          isInstalled ? "py-5" : "py-6",
        )}
      >
        <div className="col-span-1 items-center object-contain">
          {!!isListingView && (
            <BackButton to={-1} classes="w-5 h-5 my-auto mt-2" />
          )}
        </div>
        <div className="col-span-2 flex items-center justify-center">
          <Link to="/">
            <StoreLogoDevBeta className="h-7 w-fit" />
          </Link>
        </div>
        <div className="col-span-1 flex items-center justify-end"></div>
      </div>
      {(isStaticHomeView || isHomeView || isCategorySelectedView) && (
        <div className="">
          <hr className="w-full text-gray-100"></hr>
          <MobileXCategoriesBar
            selectedCategory={primary}
            selectedSubCategory={subCategory}
          />
        </div>
      )}
    </div>
  )
}

const Navbar = ({ isInstalled }) => {
  const isMobile = useIsMobile()
  const { sitePath } = useParams()
  const isListingView = !!sitePath
  const isStaticHomeView = useMatch("/")
  const isHomeView = useMatch("/home")
  const isCategorySelectedView = useMatch("/categories/*")
  const isDashboardView = useMatch("/dashboard/*")

  const user = useSelector(selectAuthUser)

  if (isMobile) {
    return (
      <MobileNavbar
        isStaticHomeView={!!isStaticHomeView}
        isHomeView={!!isHomeView}
        isCategorySelectedView={!!isCategorySelectedView}
        isListingView={isListingView}
        isInstalled={isInstalled}
      />
    )
  } else {
    return <DesktopHomeNavbar isDashboardView={!!isDashboardView} user={user} />
  }
}

export { Navbar, BackButton }
