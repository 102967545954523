import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { reducer as notificationsReducer } from "reapop"
import { PURGE_STATE_ACTION_TYPE } from "./actions/purgeState.action"
import { authSlice, authReducer } from "../../api/auth/auth.slice"
import { appsSlice, appsReducer } from "../../api/apps/apps.slice"
import { auditsSlice, auditsReducer } from "../../api/audits/audits.slice"
import { reviewsSlice, reviewsReducer } from "../../api/reviews/reviews.slice"
import {
  overridesSlice,
  overridesReducer,
} from "../../api/overrides/overrides.slice"
import {
  manifestsSlice,
  manifestsReducer,
} from "../../api/manifests/manifests.slice"
import {
  accountsReducer,
  accountsSlice,
} from "../../api/accounts/accounts.slice"
import {
  claimedAppsReducer,
  claimedAppsSlice,
} from "../../api/claimedApps/claimedApps.slice"
import reapopNotificationMiddleware from "./middleware/notifications.middleware"
import { listsSlice, listsReducer } from "../../api/lists/lists.slice"

const reducers = {
  notifications: notificationsReducer(),
  [authSlice.name]: authReducer,
  [appsSlice.name]: appsReducer,
  [manifestsSlice.name]: manifestsReducer,
  [auditsSlice.name]: auditsReducer,
  [reviewsSlice.name]: reviewsReducer,
  [accountsSlice.name]: accountsReducer,
  [claimedAppsSlice.name]: claimedAppsReducer,
  [overridesSlice.name]: overridesReducer,
  [listsSlice.name]: listsReducer,
}

const combinedReducer = combineReducers(reducers)

export const rootReducer = (state, action) => {
  if (action.type === PURGE_STATE_ACTION_TYPE) {
    state = {}
  }

  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(reapopNotificationMiddleware),
  devTools: true,
})
