import { useNavigate } from "react-router-dom"
import { Tab } from "@headlessui/react"
import { classNames } from "../../../shared/utils/utils"
import { useSearchParams } from "react-router-dom"
import { categories } from "../constants/categories"

const MobileXCategoriesBar = (props) => {
  const { selectedSubCategory, selectedCategory } = props

  const { id: categoryId, subCategories } =
    categories.find((category) => category.id === selectedCategory) || {}

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // const homeMatch = useMatch("/home")
  // const rootMatch = useMatch("/")
  // const featuredView = !!homeMatch || !!rootMatch
  return (
    <>
      <div className="block snap-x overflow-x-scroll whitespace-nowrap border-b-[1px] border-b-gray-100 bg-white scrollbar-hide">
        <Tab.Group
          selectedIndex={categories.findIndex(
            (category) => category.id === selectedCategory,
          )}
          onChange={(index) => {
            if (window.navigator.vibrate) window.navigator.vibrate(100)
            if (index === 0) {
              navigate("/")
            } else {
              navigate(`/categories/${categories[index].id}`)
            }
          }}
        >
          <Tab.List className="max-h-full max-w-7xl">
            {categories.map((tab) => (
              <Tab
                key={tab.id}
                className="without-ring focus:outline-none focus:ring-0"
              >
                {({ selected }) => (
                  <div
                    key={tab.id}
                    className={classNames(
                      selected
                        ? "border-b-2 border-gray-900 text-gray-900"
                        : "text-gray-600 hover:text-gray-800",
                      "without-ring cursor-pointer snap-start content-center p-2 text-xs font-medium scrollbar-hide focus:border-none focus:outline-none focus:ring-0",
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <div className="flex justify-center">
                      <tab.icon className="h-6 w-6" />
                    </div>
                    <div className="flex justify-center">{tab.name}</div>
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      {!!subCategories && (
        <Tab.Group
          selectedIndex={subCategories?.findIndex(
            (subCategory) => subCategory.id === selectedSubCategory,
          )}
          onChange={(index) => {
            if (window.navigator.vibrate) window.navigator.vibrate(100)
            if (index === 0) {
              navigate(`/categories/${categoryId}?${searchParams.toString()}`)
            } else {
              navigate(
                `/categories/${categoryId}/${
                  subCategories[index].id
                }?${searchParams.toString()}`,
              )
            }
          }}
          className="sticky top-0 z-50 inline-flex w-[calc(100vw-12px)] space-x-2 overflow-x-scroll bg-white p-3 scrollbar-hide"
        >
          <Tab.List>
            {subCategories.map((tab) => (
              <Tab
                key={tab.id}
                className="focus:border-none focus:outline-none focus:ring-0"
              >
                {({ selected }) => (
                  <div
                    className={classNames(
                      selected
                        ? "bg-neutral-900 text-white"
                        : "bg-gray-50 text-gray-600 hover:text-gray-800",
                      "flex cursor-pointer rounded-full px-3 py-[6px] text-sm font-medium",
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <p className="whitespace-nowrap">{tab.name}</p>
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      )}
    </>
  )
}

export { MobileXCategoriesBar }
