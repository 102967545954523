import { useMemo, useState, useEffect } from "react"

export function useIsInstalled() {
  const [isInstalled, setIsInstalled] = useState(false)

  useEffect(() => {
    setIsInstalled(window.matchMedia("(display-mode: standalone)").matches)
  }, [setIsInstalled])

  return useMemo(() => isInstalled, [isInstalled])
}
