import { Link, useMatch } from "react-router-dom"

const Footer = () => {
  const isDashboardMatch = useMatch("/dashboard/*")

  return (
    !isDashboardMatch && (
      <div className="absolute bottom-0 mt-5 w-screen bg-white">
        <div className="lg:px- mx-auto hidden max-w-7xl flex-row justify-between py-5 sm:flex sm:px-6">
          <div className="text-xs text-gray-500">
            &copy; 2023 Store.app &#183;{" "}
            <Link to="/terms" target="_blank" className="mr-1">
              Terms
            </Link>
            &#183;
            <Link to="/privacy" target="_blank" className="ml-1">
              Privacy
            </Link>
          </div>
          <div className="inline-flex text-xs text-gray-500">
            <a className="mr-1 cursor-pointer" href="mailto:support@store.app">
              Contact
            </a>
            &#183;
            <a
              className="ml-1 cursor-pointer"
              href="https://twitter.com/storedotapp"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>{" "}
          </div>
        </div>
      </div>
    )
  )
}

export default Footer
