import React, { Suspense } from "react"
import Layout from "./shared/components/layout/Layout"
import NoMatch from "./shared/components/layout/NoMatch"
import withAuth from "./shared/components/authentication/withAuth"

// Home
const Home = React.lazy(() => import("./features/home/Home"))

// StaticHome
const StaticHome = React.lazy(() => import("./features/StaticHome"))

// Listing
const Listing = React.lazy(() => import("./features/listing/Listing"))

// User Profile
const UserProfile = React.lazy(() =>
  import("./features/user/routes/UserProfile"),
)

// Logged In
const ForYou = React.lazy(() => import("./features/ForYou/ForYou"))
const AppListPage = React.lazy(() => import("./features/list/AppListPage"))

// Developer Dashboard
const DevDashboard = React.lazy(() => import("./features/developer/Developer"))
const DeveloperHome = React.lazy(() =>
  import("./features/developer/routes/DeveloperHome"),
)
const DevAccount = React.lazy(() =>
  import("./features/developer/routes/DevAccount"),
)
const DevSettings = React.lazy(() =>
  import("./features/developer/routes/DevSettings"),
)
const DeveloperApp = React.lazy(() =>
  import("./features/developer/routes/DeveloperApp"),
)

// Admin Dashboard
const AdminTable = React.lazy(() =>
  import("./features/developer/routes/AdminTable"),
)
const AdminUserManagement = React.lazy(() =>
  import("./features/developer/routes/AdminUserManagement"),
)
const AdminApp = React.lazy(() =>
  import("./features/developer/routes/AdminApp"),
)

// User Dashboard
const UserDashboard = React.lazy(() => import("./features/user/User"))
const UserHome = React.lazy(() => import("./features/user/routes/UserHome"))
const UserAccount = React.lazy(() =>
  import("./features/user/routes/UserAccount"),
)
const UserSettings = React.lazy(() =>
  import("./features/user/routes/UserSettings"),
)

// Auth
const Auth = React.lazy(() => import("./features/auth/Auth"))
const SignUp = React.lazy(() => import("./features/auth/routes/SignUp"))
const Login = React.lazy(() => import("./features/auth/routes/Login"))
const ResetPassword = React.lazy(() =>
  import("./features/auth/routes/ResetPassword"),
)
const ForgotPassword = React.lazy(() =>
  import("./features/auth/routes/ForgotPassword"),
)
const ForgotPasswordSuccess = React.lazy(() =>
  import("./features/auth/routes/ForgotPasswordSuccess"),
)

// Others
const Privacy = React.lazy(() => import("./features/terms/Privacy"))
const Terms = React.lazy(() => import("./features/terms/Terms"))
const LHReport = React.lazy(() =>
  import("./features/listing/components/LighthouseReportPage"),
)

// Authenticated Components
const ForYouWithAuth = withAuth(ForYou, "user")
const DevDashboardWithAuth = withAuth(DevDashboard, "developer")
const UserDashboardWithAuth = withAuth(UserDashboard, "user")

export const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: withFallback(StaticHome),
      },
      {
        path: "home",
        element: withFallback(Home),
      },

      {
        path: "foryou",
        element: <ForYouWithAuth />,
      },

      {
        path: ":sitePath",
        element: withFallback(Listing),
      },

      {
        path: "user/:username",
        element: withFallback(UserProfile),
      },
      {
        path: "lists/:listId",
        element: withFallback(AppListPage),
      },
      {
        path: "categories/:category",
        element: withFallback(Home),
      },
      {
        path: "categories/:category/:subCategory",
        element: withFallback(Home),
      },
      {
        path: "dashboard/developer",
        element: <DevDashboardWithAuth />,
        children: [
          {
            index: true,
            element: withFallback(DeveloperHome),
          },

          {
            path: "account",
            element: withFallback(DevAccount),
          },

          {
            path: "settings",
            element: withFallback(DevSettings),
          },
          {
            path: ":sitePath",
            element: withFallback(DeveloperApp),
          },
          {
            path: "admin",
            children: [
              {
                index: true,
                element: withFallback(AdminTable),
              },

              {
                path: "user-management",
                element: withFallback(AdminUserManagement),
              },

              {
                path: ":sitePath",
                element: withFallback(AdminApp),
              },
            ],
          },
          {
            path: "no-match",
            element: <NoMatch />,
          },
          {
            path: "*",
            element: <NoMatch />,
          },
        ],
      },
      {
        path: "dashboard/user",
        element: <UserDashboardWithAuth />,
        children: [
          {
            index: true,
            element: withFallback(UserHome),
          },

          {
            path: "account",
            element: withFallback(UserAccount),
          },

          {
            path: "settings",
            element: withFallback(UserSettings),
          },
          {
            path: "*",
            element: <NoMatch />,
          },
        ],
      },
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
  {
    path: "auth/*",
    element: withFallback(Auth),
    children: [
      {
        path: "signup",
        element: withFallback(SignUp),
      },

      {
        path: "login",
        element: withFallback(Login),
      },
      {
        path: "reset-password",
        element: withFallback(ResetPassword),
      },
      {
        path: "forgot-password",
        children: [
          {
            index: true,
            element: withFallback(ForgotPassword),
          },

          {
            path: "success",
            element: withFallback(ForgotPasswordSuccess),
          },
        ],
      },
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
  {
    path: "lhreport/:appId/:auditId",
    element: withFallback(LHReport),
  },
  {
    path: "terms",
    element: withFallback(Terms),
  },
  {
    path: "privacy",
    element: withFallback(Privacy),
  },
  {
    path: "no-match",
    element: <NoMatch />,
  },
  {
    path: "*",
    element: <NoMatch />,
  },
]

// util
function withFallback(Component) {
  return (
    <Suspense fallback="Loading...">
      <Component />
    </Suspense>
  )
}
