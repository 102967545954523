import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { willExpireIn } from "../../../api/api"
import { selectAuthUser } from "../../../api/auth/auth.slice"
import { useIsMobile } from "../../../features/home/hooks/useIsMobile"
import { useAuth } from "../../hooks/useAuth"
import { useTimeout } from "../../hooks/useTimeout"

const LOAD_DELAY = 8000
const INTERCOM_ID = process.env.REACT_APP_INTERCOM_APP_ID

const Intercom = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isMobile = useIsMobile()
  useTimeout(() => loadIntercom(() => setIsLoaded(true)), LOAD_DELAY)

  const user = useSelector(selectAuthUser)
  const { accessToken, expiresAt, refreshToken, refreshTokenExpiresAt } =
    useAuth()
  const isAccessExpired = !accessToken || willExpireIn(expiresAt, 0)
  const isRefreshExpired =
    !refreshToken || willExpireIn(refreshTokenExpiresAt, 0)

  useEffect(() => {
    if (!isLoaded || !window.Intercom || isMobile) return
    if (isAccessExpired || isRefreshExpired || !user?.id) return
    window.Intercom("boot", {
      app_id: INTERCOM_ID,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      user_id: user.id,
    })
  }, [isAccessExpired, isRefreshExpired, user, isMobile, isLoaded])

  useEffect(() => {
    if (!window.Intercom) return
    if (isMobile || (isAccessExpired && isRefreshExpired)) {
      window.Intercom("shutdown")
    }
  }, [isAccessExpired, isRefreshExpired, isMobile])

  return null
}
export default Intercom

function loadIntercom(cb) {
  if (process.env.NODE_ENV !== "production") return
  var w = window
  var ic = w.Intercom
  if (typeof ic === "function") {
    ic("reattach_activator")
    ic("update", w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var l = function () {
      var s = d.createElement("script")
      s.type = "text/javascript"
      s.async = true
      s.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`
      var x = d.getElementsByTagName("script")[0]
      x.parentNode.insertBefore(s, x)
      cb()
    }
    if (document.readyState === "complete") {
      l()
    } else if (w.attachEvent) {
      w.attachEvent("onload", l)
    } else {
      w.addEventListener("load", l, false)
    }
  }
}
