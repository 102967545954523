import { useMemo, useEffect, useState } from "react"
import { throttle } from "lodash/fp"

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640)

  useEffect(() => {
    const debouncedHandleResize = throttle(300)(() =>
      setIsMobile(window.innerWidth <= 640),
    )

    window.addEventListener("resize", debouncedHandleResize)
    debouncedHandleResize()
    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])
  return useMemo(() => isMobile, [isMobile])
}
