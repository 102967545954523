import {
  ChevronRightIcon,
  LightbulbIcon,
  ProfileIcon,
  NavigationIcon,
  DeveloperIcon,
} from "../../assets/CustomIcons"
import { Link, useMatch, useParams } from "react-router-dom"
import { classNames } from "../../utils/utils"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"
import LaunchButton from "../LaunchButton"
import { useSelector } from "react-redux"
import { selectListing } from "../../../api/apps/apps.slice"
import { getDefaultIcon, getFallbackIcon } from "../../../api/util"
import { categories } from "../../../features/home/constants/categories"
import { selectAccountByType } from "../../../api/accounts/accounts.slice"
import DevAccessModal from "../modal/DevAccessModal"
import { selectAuthUser } from "../../../api/auth/auth.slice"
import CfImage from "../CFImage"

const MobileBottomNav = ({ isInstalled }) => {
  const { sitePath } = useParams()
  const isListingView = !!sitePath
  return (
    <div
      className={classNames(
        "fixed bottom-0 z-50 flex h-20 w-screen min-w-full flex-row border-t-[1px] border-t-gray-100 bg-white py-3 px-4",
        isInstalled && "h-24 pb-6",
      )}
    >
      {isListingView ? (
        <MobileListingFooter sitePath={sitePath} isInstalled={isInstalled} />
      ) : (
        <MobileHomeFooter />
      )}
    </div>
  )
}

const MobileListingFooter = ({ sitePath, isInstalled }) => {
  const {
    data: { app, content },
  } = useSelector(selectListing(sitePath))

  const maskableIcon = getDefaultIcon(content.icons)
  const fallbackIcon = getFallbackIcon(content.icons)

  return (
    <div className="flex w-full justify-between">
      <div className="inline-flex gap-x-4">
        {!!maskableIcon ? (
          <CfImage
            src={maskableIcon.src || ""}
            alt={content.name}
            className="h-14 w-14 rounded-lg border border-gray-200 object-cover object-center"
            maxWidth={128}
          />
        ) : (
          <CfImage
            className="h-14 w-14 rounded-lg bg-gray-50 object-contain object-center p-2 sm:mx-auto"
            src={fallbackIcon?.src || ""}
            alt="App Icon"
            maxWidth={128}
          />
        )}
        <div className="flex flex-col justify-start">
          <p className="text-xl text-gray-900">{content.name}</p>
          <p className="inline-flex space-x-1 text-start text-xs font-light capitalize text-gray-700">
            {categories.find(
              (category) => category.id === content?.primary_category,
            )?.name || "..."}
            {!!content?.sub_category ? (
              <>
                <ChevronRightIcon className="h-3 w-3 self-center" />
                {content?.sub_category}
              </>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
      <LaunchButton variant="darkBlueFull" app={app} content={content} />
    </div>
  )
}

const MobileHomeFooter = () => {
  const isLoggedIn = useIsLoggedIn()
  const isHomeView = useMatch("/home")
  const isDeveloperView = useMatch("/dashboard/developer/*")
  const user = useSelector(selectAuthUser)
  const username = user?.username
  const isProfileView = useMatch(`/user/${username}`)
  const isForYouView = useMatch("/foryou")

  const devAccount = useSelector(selectAccountByType("developer"))
  const isDeveloper = !!devAccount?.data

  return (
    <>
      <Link
        to="/home"
        className={classNames(
          "flex justify-center",
          isLoggedIn ? "basis-1/4" : "basis-1/3",
        )}
      >
        <MobileBottomNavButton
          icon={NavigationIcon}
          text={"Browse"}
          active={isHomeView}
        />
      </Link>
      <Link
        to={isLoggedIn ? "/foryou" : "/auth/login"}
        className={classNames(
          "flex justify-center",
          isLoggedIn ? "basis-1/4" : "basis-1/3",
        )}
      >
        <MobileBottomNavButton
          icon={LightbulbIcon}
          text={"For You"}
          active={isForYouView}
        />
      </Link>
      {isLoggedIn &&
        (isDeveloper ? (
          <Link
            to="/dashboard/developer"
            className="flex basis-1/4 justify-center"
          >
            <MobileBottomNavButton
              icon={DeveloperIcon}
              text={"Developer"}
              active={isDeveloperView}
            />
          </Link>
        ) : (
          <DevAccessModal
            user={user}
            openButton={
              <div className="flex basis-1/4 justify-center">
                <MobileBottomNavButton
                  icon={DeveloperIcon}
                  text={"Developer"}
                  active={isDeveloperView}
                />
              </div>
            }
          ></DevAccessModal>
        ))}
      <Link
        to={isLoggedIn ? `/user/${username}` : "/auth/login"}
        className={classNames(
          "flex justify-center",
          isLoggedIn ? "basis-1/4" : "basis-1/3",
        )}
      >
        <MobileBottomNavButton
          icon={ProfileIcon}
          text={"Profile"}
          active={isProfileView}
        />
      </Link>
    </>
  )
}

const MobileBottomNavButton = (props) => {
  const { text, onClick, active } = props
  return (
    <button
      className="flex flex-col items-center justify-center"
      onClick={(event) => {
        window.scrollTo(0, 0)
        onClick && onClick()
      }}
    >
      <props.icon
        className={classNames(
          active ? "text-blue-500" : "text-gray-700",
          "h-6 w-6 flex-shrink-0",
        )}
        aria-hidden="true"
      />
      <h2
        className={classNames(
          "text-xs font-medium",
          active ? "text-blue-500" : "text-gray-700",
        )}
        aria-hidden="true"
      >
        {text}
      </h2>
    </button>
  )
}

export default MobileBottomNav
