import {
  HomeIcon,
  ProfileIcon,
  TwitterIcon,
  NavigationIcon,
  LightbulbIcon,
  OutlineHeartIcon,
  ListIcon,
  DeveloperIcon,
} from "../../assets/CustomIcons"
import { classNames } from "../../utils/utils"
import { Link, useMatch } from "react-router-dom"
import { Tab } from "@headlessui/react"
import { useSelector } from "react-redux"
import { selectAccountByType } from "../../../api/accounts/accounts.slice"
import { selectClaimedListings } from "../../../api/apps/apps.slice"
import { useLocation } from "react-router-dom"
import { selectAllLists } from "../../../api/lists/lists.slice"
import { capitalize, filter } from "lodash/fp"
import { selectAuthUser } from "../../../api/auth/auth.slice"
import { useNavigate } from "react-router-dom"
import CfImage from "../../components/CFImage"

function FooterLinks() {
  return (
    <div className="flex flex-col space-y-1">
      {/* TODO: uncomment when settings page is implemented */}
      {/* <NavItem
        item={{
          name: "Settings",
          to: "/dashboard/user/settings",
          icon: CogIcon,
        }}
        pathname={pathname}
      /> */}
      <hr className="text-gray-800"></hr>
      <div className="flex flex-col space-x-1 pt-3 text-xs text-gray-500 lg:px-2">
        <div className="flex flex-col items-center space-y-1 lg:flex-row lg:items-start lg:space-x-1 lg:space-y-0">
          <Link to="/privacy" target="_blank">
            Privacy
          </Link>
          <p className="hidden lg:flex">&middot;</p>
          <Link to="/terms" target="_blank">
            Terms
          </Link>
          <p className="hidden lg:flex">&middot;</p>
          <a href="mailto:support@store.app">Contact</a>
          <p className="hidden lg:flex">&middot;</p>
          <a
            href="https://twitter.com/storedotapp"
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon className="mr-[2px] mt-[2px] h-3 w-3 text-center text-gray-500" />
          </a>
        </div>
      </div>
      <div>
        <div className="-ml-2 inline-flex text-[10px] text-gray-400 lg:ml-0 lg:px-2">
          <p>&copy;</p>
          <p>Store.app</p>
        </div>
      </div>
    </div>
  )
}

const Sidebar = () => {
  return (
    <div className="inset-y-0 flex w-20 flex-none flex-col justify-between overflow-y-auto py-5 px-4 lg:w-56">
      <HomeSidebar />
      <FooterLinks />
    </div>
  )
}

const HomeSidebar = () => {
  const user = useSelector(selectAuthUser)
  const username = user?.username
  const navigate = useNavigate()
  const forYou = useMatch("/foryou")
  const home = useMatch("/")
  const categories = useMatch("/categories/*")
  const profile = useMatch(`/user/${username}`)
  const developer = useMatch("/dashboard/developer")
  const { pathname } = useLocation()
  const lists = useSelector(selectAllLists)
  const favorites = lists.find((list) => list.list_type === "favorites")

  const devAccount = useSelector(selectAccountByType("developer"))
  const isDeveloper = !!devAccount?.data
  const claimedList = useSelector(selectClaimedListings)
  let claimedApps = []

  const adminAccount = useSelector(selectAccountByType("admin"))
  const isAdmin = !!adminAccount?.data

  const navigation = [
    { name: "For You", to: "/foryou", icon: LightbulbIcon },
    { name: "Browse", to: "/", icon: NavigationIcon },
    // TODO: uncomment when implemented
    // { name: "Following", to: "/following", icon: StoreLogoBeta},
    { name: "Profile", to: `/user/${username}`, icon: ProfileIcon },
  ]
  if (isDeveloper) {
    navigation.push({
      name: "Developer",
      to: `/dashboard/developer`,
      icon: DeveloperIcon,
    })

    claimedApps = claimedList.map(({ app, content }) => ({
      id: app?.id,
      name: content?.short_name,
      to: `/dashboard/developer/${app?.site_path}`,
      icon: content?.icon,
      verified: app._claim?.verified_status === "verified",
    }))
  }

  const adminNavigation = [
    {
      name: "Admin Table",
      to: "/dashboard/developer/admin",
      icon: HomeIcon,
      current: true,
    },
    {
      name: "User Management Table",
      to: "/dashboard/developer/admin/user-management",
      icon: ProfileIcon,
      current: false,
    },
  ]

  return (
    <div className="overflow-y-scroll scrollbar-hide">
      <Tab.Group
        vertical={true}
        selectedIndex={
          forYou
            ? 0
            : home
            ? 1
            : categories
            ? 1
            : profile
            ? 2
            : developer
            ? 3
            : 4
        }
        onChange={(index) => navigate(navigation[index].to)}
      >
        <Tab.List className="mt-5 flex flex-col space-y-1 focus:outline-none focus:ring-0">
          {navigation.map((item) => (
            <Tab
              key={item.name}
              className="focus:border-none focus:outline-none focus:ring-0"
            >
              {({ selected }) => (
                <Link
                  to={item.to}
                  className={classNames(
                    selected
                      ? "bg-blue-50 text-blue-500 hover:text-blue-600"
                      : "text-gray-700 hover:bg-gray-50 hover:text-gray-900",
                    "without-ring group flex items-center justify-center rounded-md p-2 text-sm focus:outline-none focus:ring-0 lg:justify-start",
                  )}
                >
                  <item.icon
                    className="mr-0 h-6 w-6 flex-shrink-0 lg:mr-4 "
                    aria-hidden="true"
                  />
                  <p className="hidden lg:flex">{item.name}</p>
                </Link>
              )}
            </Tab>
          ))}
          <Tab key="invisible" className="invisible" />
          <Tab key="invisible2" className="invisible" />
        </Tab.List>
      </Tab.Group>
      {!!claimedApps?.length && (
        <div className="flex flex-col space-y-1 focus:outline-none focus:ring-0">
          <h3
            className="mt-5 text-center text-xs font-medium text-gray-500 lg:px-2 lg:text-start"
            id="projects-headline"
          >
            Claimed Apps
          </h3>
          <div
            className="space-y-1"
            role="group"
            aria-labelledby="projects-headline"
          >
            {claimedApps.map((item) => (
              <NavApp key={item.name} item={item} pathname={pathname} />
            ))}
          </div>
        </div>
      )}
      {!!lists?.length && (
        <div className="flex flex-col space-y-1 focus:outline-none focus:ring-0">
          <h3
            className="mt-5 text-center text-xs font-medium text-gray-500 lg:px-2 lg:text-start"
            id="projects-headline"
          >
            Lists
          </h3>

          {!!favorites && (
            <NavItem
              key={favorites.id}
              item={{
                name: capitalize(favorites.name),
                to: `/lists/${favorites.id}`,
                icon:
                  favorites.list_type === "favorites"
                    ? OutlineHeartIcon
                    : ListIcon,
              }}
              pathname={pathname}
            />
          )}
          {filter((list) => !!list?.id && !!list?.name)(lists)
            .filter((list) => list.list_type !== "favorites")
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((list) => (
              <NavItem
                key={list.id}
                item={{
                  name: list.name,
                  to: `/lists/${list.id}`,
                  icon:
                    list.list_type === "favorites"
                      ? OutlineHeartIcon
                      : ListIcon,
                }}
                pathname={pathname}
              />
            ))}
        </div>
      )}
      {isAdmin && (
        <div className="space-y-1">
          <h3
            className="mt-5 px-2 text-center text-xs font-medium text-gray-500 lg:text-start"
            id="projects-headline"
          >
            Admin Tools
          </h3>
          <div
            className="space-y-1"
            role="group"
            aria-labelledby="projects-headline"
          >
            {adminNavigation.map((item) => (
              <NavItem key={item.name} item={item} pathname={pathname} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const NavItem = ({ item, pathname }) => (
  <Link key={item.name} to={item?.to}>
    <span
      className={classNames(
        item?.to === pathname
          ? "bg-blue-50 text-blue-500 hover:text-blue-600"
          : "text-gray-700 hover:bg-gray-50 hover:text-gray-900",
        "without-ring group flex items-center justify-center rounded-md border-none p-2 text-sm focus:outline-none focus:ring-0 lg:justify-start",
      )}
    >
      <item.icon
        className="mr-0 h-6 w-6 flex-shrink-0 lg:mr-4"
        aria-hidden="true"
      />
      <p className="hidden lg:flex">{item.name}</p>
    </span>
  </Link>
)

const NavApp = ({ item, pathname }) => {
  return (
    <Link
      key={item.to}
      to={item.to}
      className={classNames(
        "without-ring group flex w-full items-center rounded-md py-2 text-sm lg:px-2",
        pathname.includes(item.to)
          ? "bg-blue-50 text-blue-500 hover:text-blue-600"
          : "text-gray-700 hover:bg-gray-50 hover:text-gray-900",
        !item.verified && "pointer-events-none cursor-not-allowed opacity-50",
      )}
    >
      <CfImage
        className="mx-auto inline-block h-7 w-7 rounded-md lg:mx-0 lg:mr-3"
        src={item.icon}
        alt={item.name}
        maxWidth={128}
      />
      <span className="hidden truncate lg:flex">{item.name}</span>
    </Link>
  )
}

export default Sidebar
