import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { flow } from "lodash/fp"
import { createRequestStatusSelector } from "../util"
import { authApi, BASE_URL } from "../api"
import { fetchAllContent } from "../apps/apps.slice"

export const getOverride = createAsyncThunk(
  `overrides/get`,
  async ({ appId, overrideType = "developer" }) => {
    return await authApi.get(
      `${BASE_URL}/apps/${appId}/overrides/${overrideType}`,
    )
  },
)

export const publishOverride = createAsyncThunk(
  `overrides/publish`,
  async ({ overrideId }) => {
    return await authApi.post(`${BASE_URL}/overrides/${overrideId}/publish`)
  },
)

export const createOverride = createAsyncThunk(
  `overrides/create`,
  async ({ formData, appId, overrideType = "developer" }) => {
    return await authApi.post(
      `${BASE_URL}/apps/${appId}/override?override_type=${overrideType}`,
      formData,
      {
        redirect: "follow",
      },
    )
  },
)

export const updateOverride = createAsyncThunk(
  `overrides/update`,
  async ({ formData, overrideId, overrideType = "developer" }) => {
    return await authApi.post(
      `${BASE_URL}/overrides/${overrideId}?override_type=${overrideType}`,
      formData,
      {
        redirect: "follow",
      },
    )
  },
)

export const patchOverride = createAsyncThunk(
  `overrides/update`,
  async ({ formData, overrideId, overrideType = "developer" }) => {
    return await authApi.patch(
      `${BASE_URL}/overrides/${overrideId}?override_type=${overrideType}`,
      formData,
      {
        redirect: "follow",
      },
    )
  },
)

const isAPendingAction = isPending(createOverride, updateOverride)
const isAFufilledAction = isFulfilled(createOverride, updateOverride)
const isARejectedAction = isRejected(createOverride, updateOverride)

const overridesAdapter = createEntityAdapter()
const initialState = overridesAdapter.getInitialState({
  requests: {},
})

export const overridesSlice = createSlice({
  name: "overrides",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllContent.fulfilled, (state, action) => {
        const { override, global_override } = action.payload

        if (override) {
          overridesAdapter.setOne(state, override)
        }

        if (global_override) {
          overridesAdapter.setOne(state, global_override)
        }
      })
      .addMatcher(
        isFulfilled(
          updateOverride,
          publishOverride,
          getOverride,
          createOverride,
          patchOverride,
        ),
        (state, action) => {
          const override = action.payload
          if (override?.id) {
            overridesAdapter.setOne(state, override)
          }
        },
      )
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        const id = action.meta.arg
        if (id) {
          state.requests[id] = status
        }
      })
      .addMatcher(isAFufilledAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        const id = action.meta.arg
        if (id) {
          state.requests[id] = status
        }
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        const id = action.meta.arg
        if (id) {
          state.requests[id] = status
        }
      })
  },
})

// exports
export const overridesActions = { ...overridesSlice.actions }
export const overridesReducer = overridesSlice.reducer

// selectors
// selectors
export const {
  selectRequestStatus: selectAppsRequestStatus,
  zipWithRequestStatus,
} = createRequestStatusSelector(overridesSlice.name)

export const {
  selectAll: selectAllOverrides,
  selectById: selectOverrideById,
  selectEntities: selectOverrideEntities,
  selectIds: selectOverrideIds,
} = overridesAdapter.getSelectors((state) => state.overrides)

export const selectOverrides = (state) => {
  return flow(selectAllOverrides, zipWithRequestStatus(state))(state)
}

export const selectOverride = (appId, overrideType) => (state) => {
  const overrides = selectAllOverrides(state)
  const maybeOverride = overrides.find(
    (o) => o.app_id === appId && o.override_type === overrideType,
  )
  return maybeOverride
}
