import { useField } from "formik"
import {
  ExclamationTriangleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline"
import { useState } from "react"
import { classNames } from "../../utils/utils"
import { pickBy } from "lodash/fp"

const options = (opts) => (apply) => {
  return pickBy((_, k) => apply[k])(opts)
}

const FloatingLabelTextField = ({
  label,
  classes,
  leadingIcon,
  helperText,
  progress,
  onChange, // custom onChange
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props)
  const [showPassword, setShowPassword] = useState(false)
  const isError = meta.touched && meta.error
  const isPassword = props.type === "password"

  return (
    <div className={classes?.root}>
      <div className={classNames("relative")}>
        <input
          className={classNames(
            "peer h-16 w-full rounded-lg border-none py-5 pt-[1.875rem] pb-2.5 pl-4 text-gray-900 placeholder-transparent focus:pt-[1.875rem] focus:pb-2.5 focus:ring-0",
            !isError ? "bg-neutral-100" : "bg-red-50",
            !!leadingIcon && "pl-[1.875rem]",
          )}
          {...field}
          {...{ placeholder: label }}
          {...props}
          {...options({
            type: "text",
            onChange,
          })({ type: showPassword, onChange: !!onChange })}
        />
        {!!leadingIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-4 top-5 flex items-center opacity-100 transition-all duration-300 peer-placeholder-shown:opacity-0 peer-focus:opacity-100">
            {leadingIcon}
          </div>
        )}
        {isError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {isPassword && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <button
              onClick={() => setShowPassword(!showPassword)}
              type="button"
            >
              {showPassword ? (
                <EyeSlashIcon
                  className={classNames(
                    "mt-1 h-5 w-5 text-gray-700 transition-all duration-300",
                    isError && "mr-7 mb-1",
                  )}
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon
                  className={classNames(
                    "mt-1 h-5 w-5 text-gray-700",
                    isError && "mr-7 mb-1",
                  )}
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        )}
        <label
          className={classNames(
            "pointer-events-none absolute left-4 top-3 bg-transparent text-xs transition-all duration-300",
            "peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500",
            "peer-focus:top-3 peer-focus:text-xs peer-focus:text-gray-900",
          )}
          htmlFor={props.id || props.name}
        >
          {label}
        </label>
      </div>
      {!!progress && (
        <div className="overflow-hidden rounded-b-full bg-neutral-100">
          <div
            className={classNames(
              "h-1 max-h-1 rounded-b-full transition-all",
              classes?.progress,
            )}
            style={{
              width: `${Math.round(progress * 100)}%`,
            }}
          />
        </div>
      )}
      <div
        className={classNames(
          "max-h-0 space-y-0 text-center text-sm text-gray-600 transition-all",
          isError && "max-h-6 text-red-500",
        )}
      >
        {isError && meta.error}
      </div>
    </div>
  )
}

export default FloatingLabelTextField
