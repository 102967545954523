import { Outlet } from "react-router-dom"
import { Navbar } from "../navigation/Navbar"
import MobileBottomNav from "../navigation/BottomNav.mobile"
import Sidebar from "./Sidebar"
import { classNames } from "../../utils/utils"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"
import { useIsInstalled } from "../../../features/home/hooks/useIsInstalled"
import { useIsMobile } from "../../../features/home/hooks/useIsMobile"
import { fetchAllOwnedLists } from "../../../api/lists/lists.slice"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import Footer from "./Footer"

const Layout = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const isInstalled = useIsInstalled()
  const isSignedIn = useIsLoggedIn()

  useEffect(() => {
    if (!isSignedIn) return
    dispatch(fetchAllOwnedLists())
  }, [dispatch, isSignedIn])

  if (isMobile) {
    return <MobileLayout isSignedIn={isSignedIn} isInstalled={isInstalled} />
  } else {
    return <DesktopLayout isSignedIn={isSignedIn} isInstalled={isInstalled} />
  }
}

const DesktopLayout = ({ isSignedIn, isInstalled }) => {
  return (
    <div className="h-screen bg-white">
      <Navbar isInstalled={isInstalled} />
      <div
        className={classNames(
          "relative inline-flex h-[calc(100vh-70px)] w-screen",
          !isSignedIn && "bg-gray-50 pb-12",
        )}
      >
        {isSignedIn && <Sidebar />}
        <main
          className={classNames(
            "overflow-y-scroll scrollbar-hide",
            isSignedIn
              ? "ml-0 w-full rounded-t-2xl bg-gray-50 scrollbar-hide"
              : "mx-auto max-w-7xl",
          )}
          id="main"
        >
          <div className="mx-auto max-w-7xl bg-transparent">
            <Outlet />
          </div>
        </main>
        {!isSignedIn && <Footer />}
      </div>
    </div>
  )
}

const MobileLayout = ({ isInstalled }) => {
  return (
    <div className="w-screen bg-white">
      <Navbar />
      <main className="bg-gray-50 pb-20 sm:rounded-t-2xl sm:border-t sm:border-t-gray-200">
        <Outlet />
      </main>
      <MobileBottomNav isInstalled={isInstalled} />
    </div>
  )
}

export default Layout
