import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { api, authApi, BASE_URL } from "../api"

export const getFavorites = createAsyncThunk(
  `lists/favorites/get`,
  async () => {
    const favorites = await authApi.get(`${BASE_URL}/lists/favorites`)
    return {
      ...favorites,
      app_ids: favorites.apps,
    }
  },
)

export const addFavorite = createAsyncThunk(
  `lists/favorites/post`,
  async ({ listId, appId }) =>
    await authApi.post(`${BASE_URL}/lists/favorites`, {
      app_id: appId,
      list_id: listId,
    }),
)

export const createList = createAsyncThunk(
  `lists/post`,
  async ({ name, listType }) =>
    await authApi.post(`${BASE_URL}/lists`, {
      name,
      list_type: listType,
    }),
)

export const deleteList = createAsyncThunk(`lists/delete`, async (listId) => {
  await authApi.delete(`${BASE_URL}/lists/${listId}`)
  return { listId }
})

export const addAppToList = createAsyncThunk(
  `lists/apps/post`,
  async ({ listId, appId }) =>
    await authApi.post(`${BASE_URL}/lists/${listId}/apps`, {
      app_id: appId,
    }),
)

export const removeAppFromList = createAsyncThunk(
  `lists/apps/delete`,
  async ({ listId, appId }) =>
    await authApi.delete(`${BASE_URL}/lists/${listId}/apps/${appId}`),
)

export const fetchList = createAsyncThunk(
  `lists/fetchList`,
  async (listId) => await api.get(`${BASE_URL}/lists/${listId}`),
)

export const fetchAllOwnedLists = createAsyncThunk(
  `lists/fetchAllOwnedLists`,
  async () => await authApi.get(`${BASE_URL}/lists`),
)

export const removeFavorite = createAsyncThunk(
  `lists/favorites/delete`,
  async ({ appId, listId }) => {
    await authApi.delete(`${BASE_URL}/lists/${listId}/apps/${appId}`)
  },
)

export const addFollower = createAsyncThunk(
  `lists/followers/post`,
  async (listId) => await authApi.post(`${BASE_URL}/lists/${listId}/followers`),
)

export const removeFollower = createAsyncThunk(
  `lists/followers/delete`,
  async (listId) =>
    await authApi.delete(`${BASE_URL}/lists/${listId}/followers`),
)

const isAPendingAction = isPending(getFavorites, fetchAllOwnedLists)
const isAFufilledAction = isFulfilled(getFavorites, fetchAllOwnedLists)
const isARejectedAction = isRejected(getFavorites, fetchAllOwnedLists)

const listsAdapter = createEntityAdapter()
const initialState = listsAdapter.getInitialState({
  requests: {},
})
export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllOwnedLists.fulfilled, (state, action) => {
        const lists = action.payload
        if (lists) {
          listsAdapter.setAll(state, lists)
        }
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        const favorites = action.payload
        if (favorites) {
          listsAdapter.setOne(state, favorites)
        }
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        const list = action.payload
        if (list) {
          listsAdapter.setOne(state, list)
        }
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        const listApp = action.payload
        if (listApp) {
          const { list_id, app_id } = listApp
          if (state.entities[list_id]) {
            state.entities[list_id]?.app_ids.push(app_id)
          } else {
            listsAdapter.setOne(state, {
              id: list_id,
              name: "favorites",
              list_type: "favorites",
              app_ids: [app_id],
            })
          }
        }
      })
      .addCase(createList.fulfilled, (state, action) => {
        const list = action.payload
        if (list) {
          const { id, name, list_type } = list
          listsAdapter.setOne(state, {
            id,
            name,
            list_type,
            app_ids: [],
          })
        }
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        const { listId } = action.payload
        if (listId) {
          listsAdapter.removeOne(state, listId)
        }
      })
      .addCase(addAppToList.fulfilled, (state, action) => {
        const listApp = action.payload

        if (listApp) {
          const { list_id, app_id } = listApp
          state.entities[list_id]?.app_ids.push(app_id)
        }
      })
      .addCase(removeAppFromList.fulfilled, (state, action) => {
        const { listId, appId } = action?.meta?.arg
        if (listId) {
          state.entities[listId].app_ids = state.entities[
            listId
          ]?.app_ids.filter((id) => id !== appId)
        }
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        const { listId, appId } = action?.meta?.arg
        if (listId) {
          state.entities[listId].app_ids = state.entities[
            listId
          ]?.app_ids.filter((id) => id !== appId)
        }
      })
      .addCase(addFollower.fulfilled, (state, action) => {
        const listId = action.payload
        const user = state.auth.user
        if (listId) {
          state.entities[listId].followers = state.entities[
            listId
          ].followers?.push(user?.id)
        }
      })
      .addCase(removeFollower.fulfilled, (state, action) => {
        const listId = action.payload
        const user = state.auth.user
        if (listId) {
          state.entities[listId].followers = state.entities[
            listId
          ]?.followers.filter((f) => f !== user?.id)
        }
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state) => {
        const status = { status: "pending", error: null }
        state.requests.all = status
      })
      .addMatcher(isAFufilledAction, (state) => {
        const status = { status: "fufilled", error: null }
        state.requests.all = status
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        state.requests.all = status
      })
  },
})

// exports
export const listsActions = { ...listsSlice.actions }
export const listsReducer = listsSlice.reducer

// selectors
export const {
  selectAll: selectAllLists,
  selectById: selectListById,
  selectEntities: selectListEntities,
} = listsAdapter.getSelectors((state) => state.lists)

export const selectIsFavorited = (appId) => (state) => {
  const favorites = getFavoritesList(state)
  if (favorites && favorites.app_ids) {
    return { value: favorites?.app_ids.includes(appId), id: favorites?.id }
  }

  return { value: false, id: favorites?.id }
}

export const selectIsUserFollowingList = (listId) => (state) => {
  const list = selectListById(state, listId)
  if (list) {
    const user = state.auth.user
    return list.followers?.includes(user?.id) || list.user_id === user?.id
  }
  return false
}

// helpers
const getFavoritesList = (state) => {
  return selectAllLists(state).find((l) => l.list_type === "favorites")
}
