import { useField } from "formik"

const InsetLabelTextArea = ({ label = null, showErrors = true, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props)
  return (
    <>
      <div className="bg-neutral-100 rounded-md border-0 border-transparent px-3 py-2 shadow-sm focus:ring-0">
        <label
          htmlFor="name"
          className="block text-xs font-medium text-gray-900"
        >
          {label}
        </label>
        <textarea
          className="block bg-transparent w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-[16px]"
          {...field}
          {...props}
        />
      </div>
      {showErrors && meta.touched && meta.error ? (
        <div className="text-center text-sm text-red-500 space-y-0">
          {meta.error}
        </div>
      ) : null}
    </>
  )
}

export default InsetLabelTextArea
